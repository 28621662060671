import styled from 'styled-components';

export const StyledLegendContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #fff;
`;
export const StyledDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => {
    switch (props.type) {
      case 'adjective':
        return '#FF5F5F';
      case 'noun':
        return '#4CAF50';
      case 'verb':
        return '#2196F3';
      default:
        return '#fff';
    }
  }};
`;
