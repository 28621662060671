import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BackButton } from 'components/Shared';

import {
  Container,
  LogoContainer,
  Content,
  ProgressBarContainer,
  ProgressBarLine,
  ProgressBarContent,
} from './SpellTopBar.styles';

const SpellTopBar = ({ progress, count }) => {
  const history = useHistory();
  const { subject } = useParams();

  return (
    <Container>
      <LogoContainer />
      <Content>
        <BackButton
          btnBgColor="#7b7b7b"
          backText="Back"
          className="back-btn"
          onClick={() => history.push(`/words-of-the-day/${subject}`)}
        />
        <ProgressBarContainer>
          <p>
            <span>{count}</span>/10
          </p>
          <ProgressBarLine>
            <ProgressBarContent width={(progress / 10) * 100}>
              <div className="white-line" />
            </ProgressBarContent>
          </ProgressBarLine>
        </ProgressBarContainer>
      </Content>
    </Container>
  );
};

export default SpellTopBar;
