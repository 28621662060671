import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GameList from './GameList';
import { KooCards, KooCardsOnboarding } from './KooCards';
import { SpellingBits, SpellingBitsOnboarding } from './SpellingBits';
import SpellingBitsResult from './SpellingBitsResult';
import KooCardsResult from './KooCardsResult';

const WordsOfTheDay = () => {
  return (
    <Switch>
      <Route exact path="/words-of-the-day/:subject" component={GameList} />
      <Route
        exact
        path="/words-of-the-day/:subject/koocards/tutorial"
        component={KooCardsOnboarding}
      />
      <Route
        exact
        path="/words-of-the-day/:subject/koocards"
        component={KooCards}
      />
      <Route
        exact
        path="/words-of-the-day/:subject/koocards/result"
        component={KooCardsResult}
      />
      <Route
        exact
        path="/words-of-the-day/:subject/spelling-bits"
        component={SpellingBits}
      />
      <Route
        exact
        path="/words-of-the-day/:subject/spelling-bits/tutorial"
        component={SpellingBitsOnboarding}
      />
      <Route
        exact
        path="/words-of-the-day/:subject/spelling-bits/result"
        component={SpellingBitsResult}
      />
    </Switch>
  );
};

export default WordsOfTheDay;
