import { useEffect } from 'react';
import useSound from 'use-sound';
import { useSelector, useDispatch } from 'react-redux';
import {
  pickWord,
  clearPickedWord,
} from 'store/dailyChallenge/randomWordsSlice';
import dailyChallengeSoundSprite from 'assets/audio/sprite.mp3';
import { soundSpriteMap, wordToSoundMapping } from 'constants/dailyChallenge';
const useDCcorrectSound = ({ hasSound }) => {
  const dispatch = useDispatch();
  const { pickedWord } = useSelector((state) => state.randomWords);
  const [play, { stop }] = useSound(dailyChallengeSoundSprite, {
    volume: hasSound ? 1 : 0,
    playbackRate: hasSound ? 0 : 4,
    interrupt: true,
    sprite: soundSpriteMap,
  });
  useEffect(() => {
    // Preload sound
    play({ id: 'silencegap' });
    return () => {
      stop();
    };
  }, [play, stop]);
  useEffect(() => {
    dispatch(pickWord());
    return () => {
      dispatch(clearPickedWord());
    };
  }, [dispatch]);

  const handlePlay = () => {
    if (pickedWord) {
      play({ id: wordToSoundMapping[pickedWord] });
      dispatch(pickWord());
    }
  };
  return { play: handlePlay, stop };
};

export default useDCcorrectSound;
