import styled from 'styled-components';

export const TooltipContainer = styled.div`
  background: #ff6701;
  border-radius: 16px;
  padding: 20px 24px 20px 60px;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  font-family: Linotte, sans-serif;
  font-weight: 600;
  .tooltip-arrow {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ff6701;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    justify-content: space-between;
    gap: 8px;
  }
`;

export const MikoIcon = styled.img`
  width: 129px;
  position: absolute;
  bottom: -11px;
  left: -65px;
`;
export const MikoEndIcon = styled.div`
  position: absolute;
  bottom: 84%;
  left: 50%;
  transform: translateX(-50%);
`;
export const ContentWrapper = styled.div`
  color: #ffffff;
  font-size: 18px;
  max-width: 190px;
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    max-width: unset;
    font-size: 14px;
  }
`;

export const NextButton = styled.button`
  background-color: #ffffff;
  color: #f26b3c;
  padding: 8px 32px;
  border-radius: 24px;
  border: none;
  font-weight: 600;
  font-size: 22px;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    font-size: 18px;
  }
`;
export const FinalTooltipContainer = styled.div`
  background: #ff6701;
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  max-width: 320px;
  position: relative;
  p {
    color: white;
    font-family: Linotte, sans-serif;
    font-weight: 600;
    font-size: 18px;
    &.title {
      font-size: 24px;
    }
  }
`;
