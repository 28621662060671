import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';

import MikoTutorialIcon from './miko-tutorial-icon.svg';
import { ReactComponent as MikoTutorialEndIcon } from './miko-tutorial-finished-icon.svg';

import {
  TooltipContainer,
  MikoIcon,
  ContentWrapper,
  NextButton,
  FinalTooltipContainer,
  MikoEndIcon,
} from './Tutorial.styles';

const CustomTooltip = ({
  current,
  content,
  totalSteps,
  gotoStep,
  steps,
  onCloseHandler,
}) => {
  const { t } = useTranslation(['common', 'wod']);
  const history = useHistory();
  const isLastStep = current + 1 === totalSteps;
  const currentStep = steps[current];
  const nextStep = steps[current + 1];

  const nextClickHandler = () => {
    if (!isNil(currentStep.skipTo)) {
      gotoStep(currentStep.skipTo);
    } else {
      gotoStep(current + 1);
    }
  };

  if (currentStep.isAuto && nextStep.isAuto) {
    setTimeout(() => {
      gotoStep(current + 1);
    }, 800);
  }

  if (isLastStep) {
    const handleClick = () => {
      if (onCloseHandler) {
        onCloseHandler();
      }
      history.push(steps[current]?.link);
    };

    return (
      <FinalTooltipContainer>
        <MikoEndIcon>
          <MikoTutorialEndIcon />
        </MikoEndIcon>
        <p className="title">
          {t('wod:kooCards.tutorial.end.title', 'Great !')}
        </p>
        <p className="message">
          {t(
            'wod:kooCards.tutorial.end.desc',
            'Looks like you are ready for the game.'
          )}
        </p>
        <NextButton onClick={handleClick}>
          {t('common:start', 'Start')}
        </NextButton>
      </FinalTooltipContainer>
    );
  }

  return (
    <TooltipContainer>
      {!currentStep?.hideArrow && <div className="tooltip-arrow" />}
      <MikoIcon src={MikoTutorialIcon} alt="Miko Helper" />
      <ContentWrapper>{content}</ContentWrapper>
      <NextButton onClick={nextClickHandler}>
        {t('common:next', 'Next')}
      </NextButton>
    </TooltipContainer>
  );
};

export default CustomTooltip;
