import React from 'react';

import { Button } from 'components/Shared';

import loadingGif from './loading.gif';
import loadingLight from './loading-light.png';
import { StyledContainer, StyledProgress } from './Loading.styles';
import { useTranslation } from 'react-i18next';

const Loading = ({
  message,
  progress,
  showStartBtn = false,
  startHandler,
  fadeOutAnimation,
}) => {
  const { t } = useTranslation('common');
  return (
    <StyledContainer fadeout={fadeOutAnimation}>
      <div className="content">
        <div className="loading-container">
          <img src={loadingGif} alt="loading" />
          <img
            src={loadingLight}
            className="loading-light"
            alt="loading-light"
          />
        </div>

        {showStartBtn ? (
          <Button
            variant="orange4"
            onClick={startHandler}
            fontSize="22px"
            fontWeight={600}
            className="start-btn"
          >
            {t('common:start', 'Start')}
          </Button>
        ) : (
          <StyledProgress now={progress} />
        )}
        {message}
      </div>
    </StyledContainer>
  );
};

export default Loading;
