import React from 'react';
import { useSelector } from 'react-redux';

import { LANGUAGE_SUBJECTS } from 'constants/dashboardv2';

import KooChallengeLanguage from './KooChallengeLanguage';
import KooChallengeCommon from './KooChallengeCommon';

const KooChallenge = () => {
  const { subject } = useSelector((state) => state.plan);
  const isLanguageSubjects = LANGUAGE_SUBJECTS.includes(subject);

  return (
    <>
      {isLanguageSubjects ? <KooChallengeLanguage /> : <KooChallengeCommon />}
    </>
  );
};

export default KooChallenge;
