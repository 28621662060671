import React, { useState } from 'react';
import useSound from 'use-sound';
import { isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ClaimKokoSound from 'assets/audio/claim-koko.mp3';
import ModalBgImg from 'assets/img/level/levelup-modal-bg.png';

import { PopupModal, Button } from 'components/Shared';

import { Box, Coins } from './imgs';

import {
  Container,
  Top,
  Bottom,
  Gift,
  SpinningLight,
} from './ClaimKokoCredit.styles';

const ClaimKokoCredit = ({ showModal, setShowModal, game, resultUrl }) => {
  const history = useHistory();

  const { rewards } = useSelector((state) => state.rewards);
  const { data } = useSelector((state) => state.wod.submitResult);
  const currentKoko = rewards && rewards.TotalKoko;

  const [openBoxIndex, setOpenBoxIndex] = useState(null);
  const [giftBoxGroup, setGiftBoxGroup] = useState(
    new Array(8).fill('_').map((_, i) => ({ i, open: false }))
  );

  const [play] = useSound(ClaimKokoSound, {
    volume: 1,
    playbackRate: 1,
    interrupt: true,
  });

  const handleGiftBoxClick = (i) => {
    setGiftBoxGroup((prev) =>
      prev.map((datum) => (datum.i === i ? { i, open: true } : datum))
    );
    setOpenBoxIndex(i);
    play();
  };

  const btnClickHandler = () => {
    setShowModal(false);
    history.push(resultUrl);
  };

  return (
    <PopupModal show={showModal} backdrop="static">
      {!isNil(openBoxIndex) && <SpinningLight src={ModalBgImg} alt="" />}
      <Container>
        <Top isOpen={!isNil(openBoxIndex)}>
          {!isNil(openBoxIndex) ? (
            <>
              <p className="title">
                You got{' '}
                <span className="yellow heavy-bold">{data?.koko} KoKo</span>{' '}
                rewards
              </p>
              <p className="sub-title">
                You have{' '}
                <span className="yellow">{currentKoko + data?.koko} KoKo</span>{' '}
                in total
              </p>
            </>
          ) : (
            <>
              <p className="title">You have completed {game} game today! </p>
              <p className="sub-title yellow">
                Tap to open and find out what treasure lies inside
              </p>
            </>
          )}
        </Top>
        <Bottom isOpen={!isNil(openBoxIndex)}>
          {giftBoxGroup.map(({ i, open }) => (
            <Gift key={i} onClick={() => handleGiftBoxClick(i)} isOpen={open}>
              <img
                className={open ? 'coins' : 'box'}
                src={open ? Coins : Box}
                alt="koko"
              />
              {open && (
                <p className="points heavy-bold">
                  +{data?.koko} <span>KoKo</span>
                </p>
              )}
            </Gift>
          ))}
        </Bottom>
        {!isNil(openBoxIndex) && (
          <Button
            variant="orange4"
            fontSize="22px"
            borderRadius="24px"
            width="270px"
            style={{ alignSelf: 'center', marginBottom: '2rem' }}
            onClick={btnClickHandler}
          >
            That's Awesome
          </Button>
        )}
      </Container>
    </PopupModal>
  );
};

export default ClaimKokoCredit;
