import React from 'react';
import { StyledContainer, StyledDashContainer } from './DisplayText.styles';
const DisplayText = ({
  text,
  allLength,
  showKeyboard,
  className,
  viewWidth,
}) => {
  const remainingCount = allLength - text.length;
  return (
    <StyledContainer
      allLength={allLength}
      textLength={text.length}
      showKeyboard={showKeyboard}
      className={className}
      viewWidth={viewWidth}
    >
      <span>{text}</span>
      <StyledDashContainer
        remainingCount={remainingCount}
        allLength={allLength}
      >
        {new Array(remainingCount).fill('_').map((_, index) => (
          <div className="dash" key={index} />
        ))}
      </StyledDashContainer>
    </StyledContainer>
  );
};

export default DisplayText;
