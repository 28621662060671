import {
  Grid,
  OrangeDecoLeft,
  OrangeDecoRight,
  GreenDecoLeft,
  GreenDecoRight,
} from './imgs';

export const STATUS_UI = {
  1: {
    bg: `url(${GreenDecoLeft}) no-repeat left calc(100% - 3rem), url(${GreenDecoRight}) no-repeat right calc(100% - 2rem), url(${Grid}) repeat, #BEEA9C`,
    blendMode: 'normal, normal, overlay, normal',
    inputTextColor: '#000000',
    btnText: 'Next',
    inputDisabled: true,
    bgMobile: `url(${Grid}) repeat, #BEEA9C`,
    blendModeMobile: 'overlay, normal',
  },
  0: {
    bg: `url(${Grid}) repeat, #FFDEE2`,
    blendMode: 'overlay, normal',
    inputTextColor: '#F44336',
    btnText: 'Try Again',
    inputDisabled: true,
    bgMobile: `url(${Grid}) repeat, #FFDEE2`,
    blendModeMobile: 'overlay, normal',
  },
  2: {
    bg: `url(${OrangeDecoLeft}) no-repeat left calc(100% - 3rem), url(${OrangeDecoRight}) no-repeat right calc(100% - 2rem), url(${Grid}) repeat, #ffc14b`,
    blendMode: 'normal, normal, overlay, normal',
    inputTextColor: '#000000',
    btnText: 'Check',
    inputDisabled: false,
    bgMobile: `url(${Grid}) repeat, #ffc14b`,
    blendModeMobile: 'overlay, normal',
  },
};
