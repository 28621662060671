import styled, { css, keyframes } from 'styled-components';
import { ANSWER_TYPE } from '../constant';
import { ProgressBar } from 'react-bootstrap';
import { BackButton } from 'components/Shared';
const BLOCK_WIDTH = 12;
const PROGRESS_HEIGHT = 60;

const ansStyle = {
  [ANSWER_TYPE.DEFAULT]: {
    bg: '#FFC14A',
    color: 'black',
  },
  [ANSWER_TYPE.CORRECT]: {
    bg: '#BEEA9C',
    color: 'black',
  },
  [ANSWER_TYPE.WRONG]: {
    bg: '#FFE0E2',
    color: '#F44336',
  },
};
const fadeIn = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;
export const StyledLoadingMessage = styled.div`
  margin-top: 1rem;
  font-weight: 600;
  font-family: Linotte;
  font-size: 22px;
  color: white;
  span {
    color: #ffa238;
    &.miko {
      font-size: 36px;
      font-weight: 700;
    }
  }
`;
export const StyledContainer = styled.div`
  display: flex;
  height: 100vh;
  height: 100dvh;
  opacity: 0;
  animation: ${({ isLoading, noNeedLoading }) =>
    isLoading
      ? 'none'
      : css`
          ${fadeIn} ${noNeedLoading ? 0 : 1}s ease-in-out forwards
        `};
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    position: fixed;
    flex-direction: column;
    overscroll-behavior: none;
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const StyledQuestion = styled.div`
  width: 50%;
  background-color: #232123;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: ${BLOCK_WIDTH}px ${BLOCK_WIDTH}px;
  height: 100vh;
  position: relative;
  padding-top: 60px;
  display: flex;
  &::before {
    content: '';
    position: absolute;
    width: ${BLOCK_WIDTH * 9}px;
    height: ${BLOCK_WIDTH * 11}px;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(
          rgba(255, 255, 255, 0.15) 12px,
          transparent 12px
        )
        ${BLOCK_WIDTH * 2}px ${BLOCK_WIDTH * 3}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.11) 12px, transparent 12px)
        ${BLOCK_WIDTH * 4}px ${BLOCK_WIDTH * 7}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.17) 12px, transparent 12px)
        ${BLOCK_WIDTH * 5}px ${BLOCK_WIDTH * 4}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.11) 12px, transparent 12px)
        ${BLOCK_WIDTH * 6}px ${BLOCK_WIDTH * 6}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.1) 12px, transparent 12px)
        ${BLOCK_WIDTH * 7}px ${BLOCK_WIDTH * 8}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 8}px ${BLOCK_WIDTH * 1}px / 12px 12px no-repeat;
  }
  &::after {
    content: '';
    position: absolute;
    width: ${BLOCK_WIDTH * 11}px;
    height: ${BLOCK_WIDTH * 8}px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
          rgba(255, 255, 255, 0.15) 12px,
          transparent 12px
        )
        ${BLOCK_WIDTH * 0}px ${BLOCK_WIDTH * 3}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 3}px ${BLOCK_WIDTH * 4}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 6}px ${BLOCK_WIDTH * 1}px / 12px 12px no-repeat;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    width: 100%;
    height: auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    transition: all 0.3s ease;
  }
`;
export const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 30px;
  top: 28px;
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    background: none;
    z-index: 2;
    top: 14px;
    left: 0;
    position: fixed;
  }
`;
export const StyledCardContainer = styled.div`
  position: relative;
  width: 75%;
  margin: 0 auto;
  max-width: 520px;
  padding: 0.5rem 1rem;
  @media screen and (max-width: ${({ theme }) => theme.rwd.laptop}px) {
    margin-right: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    margin: 0 auto;
    width: ${({ isKeyboardOpen }) => (isKeyboardOpen ? '30%' : '40%')};
    transform-origin: top center;
    transition: all 0.3s ease;
    display: block;
  }

  @media screen and (max-width: ${({ theme }) => theme.rwd.mobileL}px) {
    width: ${({ isKeyboardOpen }) => (isKeyboardOpen ? '40%' : '60%')};
  }
`;
export const StyledActionContainer = styled.div`
  margin-right: min(5%, 30px);
  position: absolute;
  right: 0;
  top: 66px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: ${({ theme }) => theme.rwd.laptop}px) {
    position: relative;
    top: 4px;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    position: absolute;
    margin-right: 20px;
    top: 66px;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.mobileS}px) {
    margin-right: 1rem;
    gap: 2px;
  }
`;

export const StyledProgressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #474240;
  align-items: center;
  gap: 1rem;
  height: ${PROGRESS_HEIGHT}px;
  color: white;
  .progress {
    width: 100%;
    max-width: 280px;
    height: 20px;
    background-color: #606060;
    border-radius: 10px;
    .progress-bar {
      background-color: #ff7f00;
      position: relative;
      border-radius: 10px;
      ::after {
        position: absolute;
        top: 2px;
        right: 10px;
        display: block;
        content: '';
        width: 10px;
        height: 5px;
        background: rgba(255, 196, 0, 0.4);
        border-radius: 5px;
      }
    }
  }
  .question-progress {
    font-size: 14px;
    font-family: Nunito;
    span {
      font-weight: bold;
      font-size: 24px;
      display: inline-block;
      margin-right: 4px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 48px;
    .question-progress,
    .progress {
      margin-top: 6px;
    }
    .progress {
      max-width: 180px;
    }
  }
`;
export const StyledAnswer = styled.div`
  width: 50%;
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    width: 100%;
    height: ${({ isKeyboardOpen }) => (isKeyboardOpen ? `auto` : '40%')};
    min-height: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
export const StyledAnswerArea = styled.div`
  height: calc(100vh - ${PROGRESS_HEIGHT}px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: ${({ answerType }) => ansStyle[answerType].bg};
  .input-wrap {
    padding: 25% 12% 0;
    position: relative;
  }
  .input-container {
    width: 100%;
    border-radius: 11px;
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    position: relative;
    svg {
      position: absolute;
      right: -2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input {
    border: none;
    outline: none;
    font-size: calc((90 / 1366) * 100vw);
    padding: 1rem 2rem;
    width: 100%;
    line-height: 1;
    font-family: 'Comic Sans', cursive;
    color: ${({ answerType }) => ansStyle[answerType].color};
    font-weight: bold;
    background-color: transparent;
    position: relative;
    z-index: 2;
    user-select: text;
    -webkit-user-select: text;
    -webkit-tap-highlight-color: transparent;
    overflow-x: auto;
    pointer-events: auto;
  }
  .next-button {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1;
    svg {
      transform: rotate(180deg);
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    height: 100%;
    min-height: 0;
    overflow: hidden;
    .input-wrap {
      padding: 24px 20px;
    }
    input {
      font-size: calc((66 / 767) * 100vw);
      width: 80%;
      &[readonly] {
        pointer-events: all;
        cursor: text;
        user-select: text;
      }
    }
    .input-container {
      svg {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.rwd.mobileL}px) {
    .input-wrap {
      padding: ${({ isKeyboardOpen }) =>
        isKeyboardOpen ? '24px 20px' : '12% 20px'};
    }
    input {
      font-size: calc((66 / 414) * 100vw);
    }
  }
`;
export const StyledIpadKeyboardContainer = styled.div`
  position: relative;
  align-self: flex-end;
  bottom: -100%;
  right: 0;
  margin-top: 2rem;
  ${({ showKeyboard }) =>
    showKeyboard &&
    css`
      bottom: 0;
      width: 100%;
    `}
`;
export const StyledProgress = styled(ProgressBar)`
  height: 10px;
  border-radius: 10px;
  background-color: #606060;
  .progress-bar {
    background-color: #3fc61d;
    border-radius: 10px;
  }
`;
