import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: absolute;
  width: 80%;
  bottom: ${({ viewWidth }) => (viewWidth ? '7%' : '10%')};
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ allLength }) =>
    allLength > 20 ? '0' : allLength > 8 ? '1rem 0' : '1rem 0'};
  font-size: 3rem;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-weight: bold;
  display: flex;
  gap: 2%;
  align-items: flex-end;
  justify-content: center;
  line-height: 1;
  border-radius: 1rem;
  > span {
    width: auto;
    font-size: clamp(
      16px,
      ${({ allLength, textLength, viewWidth }) =>
        viewWidth
          ? viewWidth
          : allLength > 8
          ? 3 * Math.sqrt(allLength / textLength)
          : 5}vw,
      60px
    );
    text-align: center;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: anywhere;
    white-space: normal;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    bottom: 8%;
    width: 86%;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.mobile}px) {
    padding: ${({ showKeyboard }) => (showKeyboard ? 0.5 : 1)}rem 0.5rem;
    border-radius: 0.5rem;
  }
`;
export const StyledDashContainer = styled.div`
  display: flex;
  gap: clamp(2px, 2%, 6px);
  margin-top: 12px;
  width: ${({ remainingCount }) => remainingCount * 30}px;
  max-width: ${({ allLength, remainingCount }) =>
    (remainingCount / allLength) * 100}%;
  .dash {
    height: 4px;
    background-color: #707070;
    max-width: 30px;
    flex: 1;
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    .dash {
      height: 3px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.mobile}px) {
    .dash {
      height: 2px;
    }
  }
`;
