import styled from 'styled-components';
import { CardDeco, LockIcon } from '../../imgs';

export const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #dbdbdb;
  box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.13);
  padding: 2rem 2rem 3rem 2rem;
  width: 355px;
  height: 330px;
  background: url(${({ icon }) => icon}) no-repeat calc(100% - 10px)
      ${({ top }) => top},
    url(${CardDeco}) no-repeat right top, #ffffff;
  background-size: 8.5rem, auto, auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  position: relative;
  p {
    margin-bottom: 0;
  }
  .no {
    background: #ffd422;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 34px;
    font-family: Baloo Chettan;
  }
  .game-info {
    .game-name {
      color: #0d0d0d;
      font-weight: 600;
      font-size: 24px;
    }
  }
  .rewards {
    display: flex;
    justify-content: space-between;
    .koko {
      display: flex;
      font-size: 12px;
      gap: 5px;
      align-items: center;
    }
    .result {
      color: #ff6701;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    height: 260px;
    padding: 2rem;
    .no {
      width: 40px;
      height: 40px;
      font-size: 26px;
    }
  }
`;

export const Overlay = styled.div`
  display: ${({ isLocked }) => (isLocked ? 'block' : 'none')};
  background: url(${LockIcon}) no-repeat calc(100% - 25px) calc(100% - 40px),
    rgba(160, 160, 160, 0.36);
  width: 355px;
  height: 330px;
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 1000px) {
    width: 100%;
    height: 260px;
    background: url(${LockIcon}) no-repeat calc(100% - 25px) calc(100% - 15px),
      rgba(160, 160, 160, 0.36);
    background-size: 3.8rem, auto;
  }
`;
