import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import {
  getWodPreferences,
  getWodGameStatus,
  resetWodPreferences,
} from 'store/wod/wodSlice';

import useLocalStorage from 'hooks/useLocalStorage';

import { WOD_GAMES, WOD_LEVEL_OPTIONS } from './constants';
import { WOD_STATUS } from '../constant';

import { BackButton, ModalContentXButton, PopupModal } from 'components/Shared';
import Header from 'components/Header';
import { DifficultyOptions, GameCard, RestrictionModal } from './components';

import {
  Container,
  Title,
  LevelIcon,
  Content,
  LevelContainer,
} from './GameList.styles';

const GameList = () => {
  const { t } = useTranslation(['common', 'wod']);
  const { subject } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [updatedWodLevel, setUpdatedWodLevel] = useLocalStorage(
    'updatedWodLevel',
    {},
    { validateUserId: true }
  );

  const { userID } = useSelector((state) => state.login);
  const { wodGameStatus, wodPreferences } = useSelector((state) => state.wod);

  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);

  const gameList = useCallback(() => {
    for (const key in WOD_GAMES) {
      WOD_GAMES[key].status = wodGameStatus?.data?.find(
        (game) => game.gameType === WOD_GAMES[key].id
      )?.status;
    }

    return WOD_GAMES;
  }, [wodGameStatus.data]);

  // to check if the user has not start any game yet
  const allNew = wodGameStatus?.data?.every(
    (item) => item.status === WOD_STATUS.NEW
  );

  // get current user level
  const currentLevel =
    WOD_LEVEL_OPTIONS[
      Object.keys(WOD_LEVEL_OPTIONS).find(
        (key) =>
          WOD_LEVEL_OPTIONS[key].id === wodPreferences?.data?.userWodLevel
      )
    ];

  const onClickHandler = (id, url) => {
    const hasSeenTutorial = wodPreferences?.data?.tutorialsCompleted?.find(
      (tutorial) => tutorial.gameTypeId === id
    ).completed;

    if (isNil(hasSeenTutorial) || !hasSeenTutorial) {
      history.push(`/words-of-the-day/${subject}/${url}/tutorial`);
    } else {
      history.push(`/words-of-the-day/${subject}/${url}`);
    }
  };

  useEffect(() => {
    if (!isNil(userID)) {
      dispatch(getWodPreferences());
      dispatch(getWodGameStatus());
    }
    return () => {
      dispatch(resetWodPreferences());
    };
    // eslint-disable-next-line
  }, [userID]);

  useEffect(() => {
    if (
      !isNil(userID) &&
      (isEmpty(updatedWodLevel) || !updatedWodLevel.status) &&
      !isNil(currentLevel)
    ) {
      setShowLevelModal(true);
    }
    // eslint-disable-next-line
  }, [userID, currentLevel]);

  return (
    <>
      <Header toggleAccessMenu={false} />
      <Container>
        <ModalContentXButton
          showModal={showLevelModal}
          closeModal={() => setShowLevelModal(false)}
          backdrop="static"
          borderRadius="16px"
          containerMinWidth="max-content"
        >
          <DifficultyOptions
            cancelClick={() => setShowLevelModal(false)}
            okClick={() => {
              setUpdatedWodLevel({ status: true, userID });
              setShowLevelModal(false);
            }}
            currentLevel={wodPreferences.data && currentLevel}
          />
        </ModalContentXButton>
        <PopupModal show={showErrModal} hide={() => setShowErrModal(false)}>
          <RestrictionModal clickHandler={() => setShowErrModal(false)} />
        </PopupModal>
        <Title>
          <BackButton
            btnBgColor="#F4A11B"
            backText={t('common:back', 'Back')}
            onClick={() => history.push('/dashboard?view=koochallenge')}
            className="wod-back-btn"
          />
          <LevelContainer
            onClick={() => {
              if (isNil(currentLevel)) {
                return;
              }
              if (!allNew) {
                setShowErrModal(true);
              } else {
                setShowLevelModal(true);
              }
            }}
            currentLevel={currentLevel}
          >
            <LevelIcon />
            <div className="level-name">
              {wodPreferences?.isLoading
                ? '...'
                : t(
                    `wod:levels.${currentLevel?.translationKey}.name`,
                    currentLevel?.name
                  )}
            </div>
          </LevelContainer>
        </Title>
        <Content>
          {Object.entries(gameList()).map(([key, game]) => {
            return (
              <GameCard
                key={key}
                data={game}
                clickHandler={() => onClickHandler(game.id, game.url)}
              />
            );
          })}
        </Content>
      </Container>
    </>
  );
};

export default GameList;
