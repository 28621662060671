import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import { unwrapResult } from '@reduxjs/toolkit';
import { isNil } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Lottie from 'react-lottie';
import moment from 'moment-timezone';

import {
  updateHint,
  getWordsList,
  submitGameAnswer,
  getWodGameStatus,
  getRetryWordsList,
} from 'store/wod/wodSlice';

import { getRewards } from 'store/dashboard/rewardsSlice';

import useCorrectSound from 'hooks/useCorrectSound';
import useIsMobile from 'hooks/responsive/useIsMobile';
import useWrongSound from 'hooks/useWrongSound';
import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import { useLoading } from 'hooks/useLoading';

import useVirtualKeyboardDetection from './useVirtualKeyboardDetection';

import { ReactComponent as BackIcon } from 'assets/img/icon-back.svg';
import { ReactComponent as IconWrong } from './imgs/wrongIcon.svg';
import animationData from './lf30_editor_xakgefzo.json';

import { ANSWER_TYPE, WOD_GAME_TYPE, WOD_STATUS } from '../constant';

import { Button, Keyboard, ErrorModal } from 'components/Shared';
import {
  Loading,
  Hint,
  Voice,
  MeaningAndTranslateModal,
  VocabCard,
  MeaningAndTranslation,
  ClaimKokoCredit,
} from 'pages/WordsOfTheDay/components';
import { Modifier } from './components';

import {
  StyledLoadingMessage,
  StyledContainer,
  StyledAnswer,
  StyledQuestion,
  StyledCardContainer,
  StyledActionContainer,
  StyledProgressContainer,
  StyledAnswerArea,
  StyledBackButton,
  StyledIpadKeyboardContainer,
  StyledProgress,
} from './KooCards.styles';

const KooCards = () => {
  const { t } = useTranslation(['wod', 'common']);
  const isBelowTablet = useIsBelowTablet();
  const isMobile = useIsMobile();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { subject } = useParams();

  const { play: playCorrectSound } = useCorrectSound();
  const { play: playWrongSound } = useWrongSound();

  const { userID, timezone } = useSelector((state) => state.login);
  const { wordsList, hint, submitResult, wodGameStatus, gameResult } =
    useSelector((state) => state.wod);

  const [inputValue, setInputValue] = useState('');
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [order, setOrder] = useState(1);
  const [correctCount, setCorrectCount] = useState(0);
  const [hintText, setHintText] = useState('');
  const [currentAnswerType, setCurrentAnswerType] = useState(
    ANSWER_TYPE.DEFAULT
  );
  const [displayContent, setDisplayContent] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showKokoModal, setShowKokoModal] = useState(false);

  const { hasVirtualKeyboard } = useVirtualKeyboardDetection();
  const containerRef = useRef(null);
  const kooCardDatum =
    wordsList?.koocardsList?.find((word) =>
      isFirstLoad ? !word.result : word.wordOrder === order
    ) ?? null;
  const allLoading =
    wordsList.isLoading || wodGameStatus.isLoading || gameResult.isLoading;
  const { progress } = useLoading(allLoading);
  const isLoading = progress !== 100;
  const percentage =
    (1 / (kooCardDatum?.wordText.length - 1)) * (hintText.length - 1);
  const koocardsStatus = wodGameStatus?.data?.find(
    (data) => data.gameType === WOD_GAME_TYPE.KOOCARDS
  );

  const handleGetKooCards = useCallback(() => {
    if (koocardsStatus?.status === WOD_STATUS.COMPLETED) {
      dispatch(getRetryWordsList({ gameType: WOD_GAME_TYPE.KOOCARDS }));
    } else {
      dispatch(
        getWordsList({
          gameType: WOD_GAME_TYPE.KOOCARDS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
          status: koocardsStatus?.status,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, koocardsStatus]);

  useEffect(() => {
    if (!isNil(userID) && !isNil(wodGameStatus.data)) {
      handleGetKooCards();
    }
    // eslint-disable-next-line
  }, [handleGetKooCards, userID, koocardsStatus]);

  useEffect(() => {
    if (!isNil(userID) && isNil(wodGameStatus.data)) {
      dispatch(getWodGameStatus());
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  useEffect(() => {
    if (kooCardDatum) {
      const hintIndex = kooCardDatum.wordHintIdx
        ? kooCardDatum.wordHintIdx + 1
        : 1;
      setHintText(() => kooCardDatum.wordText.slice(0, hintIndex));
      setOrder(kooCardDatum.wordOrder);
      setCorrectCount(kooCardDatum.wordOrder - 1);
    }
  }, [kooCardDatum]);

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch, subject]);

  const renderButtonText = useCallback(() => {
    switch (currentAnswerType) {
      case ANSWER_TYPE.CORRECT: {
        return (
          <div className="next-button">
            {t('common:next', 'Next')}
            <BackIcon />
          </div>
        );
      }
      case ANSWER_TYPE.WRONG: {
        return t('common:tryAgain', 'Try Again');
      }
      default: {
        return t('common:check', 'Check');
      }
    }
  }, [currentAnswerType, t]);

  const handleButtonClick = async () => {
    switch (currentAnswerType) {
      case ANSWER_TYPE.CORRECT: {
        if (order < wordsList?.koocardsList?.length) {
          setCurrentAnswerType(ANSWER_TYPE.DEFAULT);
          setOrder((prev) => prev + 1);
          setInputValue('');
        }
        return;
      }
      case ANSWER_TYPE.WRONG: {
        setCurrentAnswerType(ANSWER_TYPE.DEFAULT);
        return;
      }
      default: {
        setShowKeyboard(false);
        setIsFirstLoad(false);
        try {
          const submitAction = await dispatch(
            submitGameAnswer({
              gameWordId: kooCardDatum.gameWordId,
              answer: inputValue,
            })
          );
          const res = unwrapResult(submitAction);
          if (res.gameResult) {
            playCorrectSound();
            setCurrentAnswerType(ANSWER_TYPE.CORRECT);
            if (res.koko > 0) setShowKokoModal(true);
            if (correctCount !== wordsList?.koocardsList?.length)
              setCorrectCount((prev) => prev + 1);
          } else {
            playWrongSound();
            setCurrentAnswerType(ANSWER_TYPE.WRONG);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleInputClick = () => {
    if (hasVirtualKeyboard) {
      setShowKeyboard(true);
    } else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };
  const handleInputChange = (value) => {
    const formattedInput = value.toLowerCase();
    setInputValue(formattedInput);
  };

  const handleHintClick = async () => {
    try {
      const action = await dispatch(updateHint(kooCardDatum.gameWordId));
      const result = unwrapResult(action);
      const text = kooCardDatum.wordText;
      if (result) {
        setHintText((prev) => text.slice(0, prev.length + 1));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = (contentButton) => {
    setDisplayContent(contentButton);
    if (!openModal) {
      setOpenModal(true);
    }
  };
  const handleClose = () => {
    setDisplayContent(null);
    setOpenModal(false);
  };

  return (
    <>
      <Loading
        progress={progress}
        fadeOutAnimation={progress === 100}
        message={
          <StyledLoadingMessage>
            <Trans i18nKey="wod:kooCards.loading">
              <span className="miko">Miko</span> is{' '}
              <span>personalising 10 words</span> for you!
            </Trans>
          </StyledLoadingMessage>
        }
      />
      <ClaimKokoCredit
        showModal={showKokoModal}
        resultUrl={`/words-of-the-day/${subject}/koocards/result`}
        game="KooCards"
        setShowModal={setShowKokoModal}
      />

      <StyledContainer ref={containerRef} isLoading={isLoading}>
        {!isNil(wordsList.isError) && !isLoading && (
          <ErrorModal
            errorMessage="Something went wrong"
            reloadAction={handleGetKooCards}
            backText="Back"
            closeHandler={() => history.push(`/words-of-the-day/${subject}`)}
          />
        )}
        {!isNil(kooCardDatum) && !isLoading && (
          <>
            <StyledQuestion isKeyboardOpen={showKeyboard}>
              <StyledBackButton
                backText={isBelowTablet ? '' : t('common:back', 'Back')}
                btnBgColor="#404447"
                padding="0.3rem 1.2rem"
                onClick={() => history.push(`/words-of-the-day/${subject}`)}
              />
              <StyledCardContainer isKeyboardOpen={showKeyboard}>
                <VocabCard
                  url={kooCardDatum.imageUrl}
                  textLength={kooCardDatum.wordText.length}
                  hintText={hintText}
                  showKeyboard={showKeyboard}
                />
                <Modifier />
              </StyledCardContainer>
              <StyledActionContainer>
                <Hint
                  disabled={percentage === 1 || hint.isLoading}
                  onClick={handleHintClick}
                >
                  <StyledProgress now={100 - Math.floor(percentage * 100)} />
                </Hint>
                <Voice audioLink={kooCardDatum.voiceOverUrl} />
                {currentAnswerType === ANSWER_TYPE.CORRECT && (
                  <>
                    <MeaningAndTranslation
                      handleOpen={handleOpen}
                      displayContent={displayContent}
                    />
                    <MeaningAndTranslateModal
                      openModal={openModal}
                      type={displayContent}
                      closeHandler={handleClose}
                      gameWordId={kooCardDatum.gameWordId}
                      wordEntryId={kooCardDatum.wordEntryId}
                      imgUrl={kooCardDatum.imageUrl}
                      word={kooCardDatum.wordText}
                      wordClass={kooCardDatum.wordClass}
                    />
                  </>
                )}
              </StyledActionContainer>
            </StyledQuestion>
            <StyledAnswer isKeyboardOpen={showKeyboard}>
              <StyledProgressContainer>
                <div className="question-progress">
                  <span>{order}</span>/{wordsList?.koocardsList?.length}
                </div>
                <ProgressBar
                  now={(correctCount / wordsList?.koocardsList?.length) * 100}
                />
              </StyledProgressContainer>
              <StyledAnswerArea
                answerType={currentAnswerType}
                isKeyboardOpen={showKeyboard}
              >
                <div className="input-wrap">
                  <div className="input-container" onClick={handleInputClick}>
                    <input
                      value={inputValue}
                      placeholder={kooCardDatum.wordText[0]}
                      disabled={currentAnswerType !== ANSWER_TYPE.DEFAULT}
                      ref={inputRef}
                      readOnly={hasVirtualKeyboard}
                      type="text"
                      autoComplete="off"
                      inputMode="none"
                      onChange={(e) => handleInputChange(e.target.value)}
                      autoCapitalize="off"
                    />
                    {currentAnswerType === ANSWER_TYPE.WRONG && <IconWrong />}
                  </div>

                  <Button
                    margin={isBelowTablet ? '1rem auto 0.75rem' : '2rem auto 0'}
                    variant="primary"
                    width={isBelowTablet ? '96%' : '100%'}
                    onClick={handleButtonClick}
                    isDisabled={submitResult.isLoading}
                    disableColor="#ff7121"
                    style={{ zIndex: 2, position: 'relative' }}
                  >
                    {renderButtonText()}{' '}
                    {submitResult.isLoading && (
                      <Spinner animation="border" size="sm" />
                    )}
                  </Button>
                  {currentAnswerType === ANSWER_TYPE.CORRECT && (
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      isStopped={false}
                      isPaused={false}
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: 1,
                      }}
                    />
                  )}
                </div>
                {hasVirtualKeyboard && !isMobile && (
                  <StyledIpadKeyboardContainer showKeyboard={showKeyboard}>
                    <Keyboard
                      onChange={handleInputChange}
                      showKeyboard={showKeyboard}
                      inputRef={inputRef}
                    />
                  </StyledIpadKeyboardContainer>
                )}
              </StyledAnswerArea>
            </StyledAnswer>
            {hasVirtualKeyboard && isMobile && (
              <Keyboard
                onChange={handleInputChange}
                showKeyboard={showKeyboard}
                inputRef={inputRef}
              />
            )}
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default KooCards;
