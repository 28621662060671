import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledDot,
  StyledLegendContainer,
  StyledLegendItem,
} from './Modifier.styles';
const Modifier = () => {
  const { t } = useTranslation(['wod']);
  return (
    <StyledLegendContainer>
      <StyledLegendItem>
        <StyledDot type="adjective" />
        {t('wod:kooCards.modifier.adjective', 'adjective')}
      </StyledLegendItem>
      <StyledLegendItem>
        <StyledDot type="noun" />
        {t('wod:kooCards.modifier.noun', 'noun')}
      </StyledLegendItem>
      <StyledLegendItem>
        <StyledDot type="verb" />
        {t('wod:kooCards.modifier.verb', 'verb')}
      </StyledLegendItem>
    </StyledLegendContainer>
  );
};

export default Modifier;
