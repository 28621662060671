import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import { KokoIcon } from '../GameList/imgs';
import BackIcon from 'assets/img/icon-back.svg';

const BLOCK_WIDTH = 12;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #232123;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 12px 12px;
  min-height: 100vh;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    width: ${BLOCK_WIDTH * 9}px;
    height: ${BLOCK_WIDTH * 11}px;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(
          rgba(255, 255, 255, 0.15) 12px,
          transparent 12px
        )
        ${BLOCK_WIDTH * 2}px ${BLOCK_WIDTH * 3}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.11) 12px, transparent 12px)
        ${BLOCK_WIDTH * 4}px ${BLOCK_WIDTH * 7}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.17) 12px, transparent 12px)
        ${BLOCK_WIDTH * 5}px ${BLOCK_WIDTH * 4}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.11) 12px, transparent 12px)
        ${BLOCK_WIDTH * 6}px ${BLOCK_WIDTH * 6}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.1) 12px, transparent 12px)
        ${BLOCK_WIDTH * 7}px ${BLOCK_WIDTH * 8}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 8}px ${BLOCK_WIDTH * 1}px / 12px 12px no-repeat;
  }
  &::after {
    content: '';
    position: absolute;
    width: ${BLOCK_WIDTH * 11}px;
    height: ${BLOCK_WIDTH * 8}px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(
          rgba(255, 255, 255, 0.15) 12px,
          transparent 12px
        )
        ${BLOCK_WIDTH * 0}px ${BLOCK_WIDTH * 3}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 3}px ${BLOCK_WIDTH * 4}px / 12px 12px no-repeat,
      linear-gradient(rgba(255, 255, 255, 0.15) 12px, transparent 12px)
        ${BLOCK_WIDTH * 6}px ${BLOCK_WIDTH * 1}px / 12px 12px no-repeat;
  }
  @media (max-width: 1024px) {
    &::before,
    ::after {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  margin: auto;
  gap: 2.5rem;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ResultContainer = styled.div`
  display: flex;
  margin: auto;
  gap: 2.5rem;
  flex-wrap: wrap;
  max-width: calc((205px * 5) + (2.5rem * 4));
  justify-content: center;
  .card-group {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    flex: 0 0 205px;
  }
`;

export const ActionBtns = styled.div`
  display: flex;
  gap: 2rem;
  .orange4 {
    &::before {
      content: '';
      display: block;
      background-image: url(${BackIcon});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 1rem;
      height: 1rem;
      filter: invert(100%) brightness(100%);
      transform: rotate(180deg);
      right: ${({ showIcon }) => (showIcon ? '1.2rem' : '1.8rem')};
    }

    &::after {
      content: '';
      width: 42px;
      height: 42px;
      display: ${({ showIcon }) => (showIcon ? 'block' : 'none')};
      background-image: url(${KokoIcon});
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 2.5rem;
      bottom: 0.3rem;
    }
  }
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    background: #2f2f30;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
`;

export const StyledProgress = styled(ProgressBar)`
  height: 10px;
  border-radius: 10px;
  background-color: #606060;
  width: 80%;

  .progress-bar {
    background-color: #3fc61d;
    border-radius: 10px;
  }
`;
