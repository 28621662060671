import styled from 'styled-components';
import Ribbon from './ribbon.svg';

export const Container = styled.div`
  height: 62px;
  width: 100%;
  display: flex;
  background: #3c3737;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  .back-btn {
    padding: 0.25rem 1.2rem;
    position: absolute;
    left: 1rem;
    @media (max-width: 768px) {
      background: transparent;
      left: 0.2rem;
      .button-text {
        display: none;
      }
    }
  }
`;

export const RibbonContainer = styled.div`
  background: url(${Ribbon});
  color: #000;
  padding: 1rem 4rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media (max-width: 630px) {
    font-size: 24px;
    font-weight: 600;
    width: 80%;
    text-align: center;
  }
`;
