import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 200px;
  height: 200px;
  @media (max-width: 768px) {
    width: 175px;
    height: 175px;
  }
`;

export const Second = styled.div`
  font-family: 'Nunito';
  font-size: 72px;
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`;

export const CircleContainer = styled.svg`
  width: 200;
  height: 200;
  @media (max-width: 768px) {
    width: 175;
    height: 175;
  }
`;
