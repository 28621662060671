import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isNil } from 'ramda';

import { getGameResult, getWodGameStatus } from 'store/wod/wodSlice';

import { WOD_GAME_TYPE } from '../constant';

import {
  ResultTopBar,
  VocabCard,
  MeaningAndTranslateModal,
} from '../components';
import { Button, Spinner } from 'components/Shared';

import {
  Container,
  Content,
  StyledProgress,
  ResultContainer,
  ActionBtns,
} from './KooCardsResult.styles';

const KooCardsResult = () => {
  const history = useHistory();
  const { subject } = useParams();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [selectedWord, setSelectedWord] = useState(null);

  const { userID, timezone } = useSelector((state) => state.login);
  const { data, isLoading } = useSelector((state) => state.wod.gameResult);
  const { wodGameStatus } = useSelector((state) => state.wod);

  const spellingBitsAttemptCount = wodGameStatus?.data?.find(
    (data) => data.gameType === WOD_GAME_TYPE.SPELLING_BITS
  )?.dailyAttempts;

  const goToCoverPage = () => history.push(`/words-of-the-day/${subject}`);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!isNil(userID)) {
      dispatch(
        getGameResult({
          gameType: WOD_GAME_TYPE.KOOCARDS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
        })
      );
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  useEffect(() => {
    if (!isNil(userID) && isNil(wodGameStatus.data)) {
      dispatch(getWodGameStatus());
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  return (
    <Container>
      <MeaningAndTranslateModal
        inModal
        openModal={showModal}
        closeHandler={handleCloseModal}
        type="meaning"
        imgUrl={selectedWord?.imgUrl}
        gameWordId={selectedWord?.gameWordId}
        word={selectedWord?.wordText}
        wordEntryId={44}
      />
      <ResultTopBar game="KooCards" backHandler={goToCoverPage} />
      <Content>
        {isLoading && <Spinner color="#ffffff" />}
        {!isLoading && data && (
          <>
            <ResultContainer>
              {data?.gameWords?.map((card) => (
                <div
                  key={card.id}
                  className="card-group"
                  onClick={() => {
                    setSelectedWord(card);
                    setShowModal(true);
                  }}
                >
                  <VocabCard
                    url={card.imgUrl}
                    textLength={card.wordText.length}
                    hintText={card.wordText}
                    viewWidth={2}
                    animatedOnHover
                  />
                  <StyledProgress
                    now={
                      ((card.wordText.length - 1 - card.wordHintIdx) /
                        (card.wordText.length - 1)) *
                      100
                    }
                  />
                </div>
              ))}
            </ResultContainer>
            <ActionBtns showIcon={spellingBitsAttemptCount === 0}>
              <Button
                variant="transparent"
                fontSize="22px"
                padding="0.6rem 2.5rem"
                onClick={() =>
                  history.push(`/words-of-the-day/${subject}/koocards`)
                }
              >
                Play Again
              </Button>
              <Button
                variant="orange4"
                fontSize="22px"
                padding={
                  spellingBitsAttemptCount === 0
                    ? '0.6rem 5.5rem 0.6rem 2.5rem'
                    : '0.6rem 3rem 0.6rem 2.5rem'
                }
                style={{ position: 'relative' }}
                onClick={goToCoverPage}
              >
                Next Game
              </Button>
            </ActionBtns>
          </>
        )}
      </Content>
    </Container>
  );
};

export default KooCardsResult;
