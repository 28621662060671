import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import checkIsOpen from 'helpers/Dashboard/checkIsOpen';
import useQuery from 'helpers/useQuery';
import { isNil } from 'ramda';

import { sidePanelNavigation } from 'constants/dashboardv2';
import MobileToggleFeature from 'components/Shared/MobileToggleFeature';
import { useKooClassEnabledStatus } from './components/SubpageNav/SubpageNav';

import { Page } from './Dashboardv2.styles';

import { SidePanel } from './components';
import KooChallenge from './KooChallenge';
import KooClassLocked from './KooClassLocked';
import KooFun from './KooFun';
import KooTraining from './KooTraining';
import KooClass from './KooClass';

const Dashboardv2 = () => {
  const query = useQuery();
  const viewParam = query.get('view') ?? '';
  const { timezone, userID } = useSelector((state) => state.login);
  const { currentProduct } = useSelector((state) => state.plan);
  const subjectName = currentProduct?.subject.toLowerCase();

  const [activeView, setActiveView] = useState(1);
  const { kooClassIsEnabled } = useKooClassEnabledStatus();
  useEffect(() => {
    if (viewParam.length > 0) {
      const activeViewObj = sidePanelNavigation.find(
        (view) =>
          view.name.toLowerCase().trim() === viewParam.toLowerCase().trim()
      );
      if (!isNil(activeViewObj)) {
        setActiveView(activeViewObj.id);
        return;
      }
    }
    if (userID !== null) {
      const isOpen = checkIsOpen(timezone);
      if (isOpen) {
        setActiveView(1);
      } else {
        setActiveView(2);
      }
    }
  }, [timezone, userID, viewParam]);
  return (
    <Page
      className={`page-b2c-${subjectName}`}
      subject={subjectName}
      data-cy="home-page"
      activeView={activeView}
      styles={{
        pageHeight:
          activeView === 3 && kooClassIsEnabled ? 'auto' : 'calc(100vh - 59px)',
      }}
    >
      <SidePanel
        activeView={activeView}
        setActiveView={setActiveView}
        subject={subjectName}
        styles={{
          minHeight:
            activeView === 3 && kooClassIsEnabled
              ? 'auto'
              : 'calc(100vh - 59px)',
        }}
      />
      {activeView === 1 && <KooChallenge />}
      {activeView === 2 && <KooTraining />}
      {activeView === 3 &&
        (kooClassIsEnabled ? (
          <KooClass subject={subjectName} />
        ) : (
          <KooClassLocked />
        ))}
      {activeView === 4 && <KooFun />}
      <MobileToggleFeature />
    </Page>
  );
};

export default Dashboardv2;
