import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TrialSelector, Button, ButtonPlay } from 'components/Shared';
import MikoSci from 'assets/img/science/miko-img.svg';
import {
  Container,
  CountDown,
  StyledPopupModal,
  StartBtnContainer,
  Mascot,
} from './TrialBanner.styles';

const TrialBanner = ({ currentDay, userId, isOTPTrial }) => {
  const { t } = useTranslation(['trial']);
  const [showModal, setShowModal] = useState(false);
  const { subject } = useSelector((state) => state.plan);
  const leftDays = 7 - currentDay;

  const closeModal = () => setShowModal(false);
  const handleStart = () => {
    window.open(`https://www.koobits.com/${subject}/start`);
  };
  return (
    <>
      <StyledPopupModal
        show={showModal}
        onHide={closeModal}
        centered
        backdrop="static"
        size="xl"
      >
        <TrialSelector
          onClose={closeModal}
          userId={userId}
          campaignCode="BottomBanner"
        />
      </StyledPopupModal>
      <Container data-cy="trial-banner">
        <CountDown>
          <div className={`count-down ${currentDay >= 7 && 'red'}`}>
            {t('trial:day', { number: currentDay }, `Day ${currentDay}`)}
          </div>
          <p>
            {t('trial:freeTrial', 'of free trial')} ({' '}
            <b>
              {t(
                `trial:remaining_${leftDays > 1 ? 'plural' : 'single'}`,
                {
                  number: leftDays,
                },
                `${leftDays > 1 ? 'days' : 'day'} left`
              )}
            </b>{' '}
            )
          </p>
          {isOTPTrial ? (
            <>
              <Button
                variant="primary"
                fontWeight="bold"
                margin="0 10px 0 1rem"
                fontSize="20px"
                onClick={() => setShowModal(true)}
              >
                {t('trial:upgrade', 'Upgrade your plan')}
              </Button>
              <p>{t('trial:unlock', 'to unlock the full version')}</p>
            </>
          ) : (
            <StartBtnContainer
              className="start"
              aria-hidden="true"
              onClick={handleStart}
              isOtp={isOTPTrial}
            >
              <p>{t('trial:getStart', 'Let‘s get started')}</p>
              <ButtonPlay size="33px" variant="primary" />
            </StartBtnContainer>
          )}
        </CountDown>
        <div className="start-wrap">
          <Mascot
            src={MikoSci}
            className="mascot"
            alt="miko"
            isOtp={isOTPTrial}
          />
          {isOTPTrial && (
            <StartBtnContainer
              className="start"
              aria-hidden="true"
              onClick={handleStart}
              isOtp={isOTPTrial}
            >
              <p>{t('trial:getStart', 'Let‘s get started')}</p>
              <ButtonPlay size="33px" variant="primary" />
            </StartBtnContainer>
          )}
        </div>
      </Container>
    </>
  );
};

export default TrialBanner;
