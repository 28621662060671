import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import useSound from 'use-sound';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { unwrapResult } from '@reduxjs/toolkit';

import { Spinner } from 'react-bootstrap';

import { pickWord } from 'store/dailyChallenge/randomWordsSlice';

import {
  getWordsList,
  updateHint,
  submitGameAnswer,
  getRetryWordsList,
  getWodGameStatus,
} from 'store/wod/wodSlice';

import { SpellTopBar, HintCountDown } from './components';
import {
  Voice,
  Hint,
  Loading,
  ClaimKokoCredit,
  MeaningAndTranslation,
  MeaningAndTranslateModal,
} from '../components';

import { STATUS_UI } from './constant';
import { soundSpriteMap, wordToSoundMapping } from 'constants/dailyChallenge';
import { ANSWER_TYPE } from '../constant';

import { WOD_GAME_TYPE, WOD_STATUS } from '../constant';
import { useLoading } from 'hooks/useLoading';

import { MikoHear, WrongIcon } from './imgs';

import wrongSound from './wrong.mp3';
import dailyChallengeSoundSprite from 'assets/audio/sprite.mp3';

import {
  Container,
  Content,
  Input,
  HintAnswer,
  Button,
  LoadingContent,
  MeaningTranslateContainer,
} from './SpellingBits.styles';

const SpellingBits = () => {
  const dispatch = useDispatch();

  const { subject } = useParams();

  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState(ANSWER_TYPE.DEFAULT);
  const [hint, setHint] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [progressQn, setProgressQn] = useState(1);
  const [correctCount, setCorrectCount] = useState(0);

  const [showLoading, setShowLoading] = useState(true);
  const [claimKoko, setClaimKoko] = useState(false);
  const [showStartBtn, setShowStartBtn] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [displayModalType, setDisplayModalType] = useState(null);
  const [showMeaningModal, setShowMeaningModal] = useState(false);

  const { pickedWord } = useSelector((state) => state.randomWords);
  const { wordsList, wodGameStatus, submitResult } = useSelector(
    (state) => state.wod
  );
  const { userID, timezone } = useSelector((state) => state.login);

  const loading = wordsList?.isLoading || wodGameStatus?.isLoading;
  const { progress } = useLoading(loading);

  const spellingBitsStatus = wodGameStatus?.data?.find(
    (data) => data.gameType === WOD_GAME_TYPE.SPELLING_BITS
  );

  const currentList =
    spellingBitsStatus?.status === WOD_STATUS.IN_PROGRESS
      ? wordsList?.spellingBitsList
      : wordsList?.data;

  const currentQn =
    currentList?.find((word) =>
      isFirstLoad ? !word.result : word.wordOrder === progressQn
    ) ?? null;

  console.log({ currentList });

  const [play, { isPlaying, sound, stop }] = useSound(currentQn?.voiceOverUrl, {
    interrupt: false,
  });

  const [playWrongSound] = useSound(wrongSound);
  const [playCorrectSound] = useSound(dailyChallengeSoundSprite, {
    interrupt: true,
    sprite: soundSpriteMap,
  });

  const clickHandler = async () => {
    console.log('a');
    switch (status) {
      case ANSWER_TYPE.CORRECT: {
        setStatus(ANSWER_TYPE.DEFAULT);
        setAnswer('');
        setProgressQn((prev) => prev + 1);
        return;
      }
      case ANSWER_TYPE.WRONG: {
        setStatus(ANSWER_TYPE.DEFAULT);
        return;
      }
      default: {
        setIsFirstLoad(false);
        try {
          const submitAction = await dispatch(
            submitGameAnswer({
              gameWordId: currentQn?.gameWordId,
              answer,
            })
          );
          const res = unwrapResult(submitAction);
          console.log({ res });
          if (res.gameResult) {
            setStatus(ANSWER_TYPE.CORRECT);
            if (res.koko > 0) setClaimKoko(true);
            if (correctCount !== currentList?.length)
              setCorrectCount((prev) => prev + 1);
            dispatch(pickWord());
            playCorrectSound({ id: wordToSoundMapping[pickedWord] });
          } else {
            playWrongSound();
            setStatus(ANSWER_TYPE.WRONG);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const hintHandler = () => {
    if (isPlaying) {
      stop();
    }
    setHint(true);
    setAnswer(currentQn.wordText);
    setStatus(ANSWER_TYPE.DEFAULT);
    setTimeout(() => {
      play();
    }, 1000);
    dispatch(updateHint(currentQn?.gameWordId));
  };

  const removeCountDown = () => {
    setTimeout(() => {
      setShowCountDown(false);
      setAnswer('');
      setHint(false);
    }, 1000);
  };

  const handleStart = () => {
    setIsTransitioning(true);
    setShowLoading(false);
  };

  const handleOpen = (contentButton) => {
    setDisplayModalType(contentButton);
    if (!showMeaningModal) {
      setShowMeaningModal(true);
    }
  };

  const handleClose = () => {
    setDisplayModalType(null);
    setShowMeaningModal(false);
  };

  const getSpellingBits = useCallback(() => {
    if (spellingBitsStatus?.status === WOD_STATUS.COMPLETED) {
      dispatch(getRetryWordsList({ gameType: WOD_GAME_TYPE.SPELLING_BITS }));
    } else if (
      spellingBitsStatus?.status === WOD_STATUS.IN_PROGRESS ||
      isEmpty(wordsList?.data)
    ) {
      dispatch(
        getWordsList({
          gameType: WOD_GAME_TYPE.SPELLING_BITS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
          status: spellingBitsStatus?.status,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, spellingBitsStatus]);

  useEffect(() => {
    if (!isNil(userID) && !isNil(wodGameStatus.data)) {
      getSpellingBits();
    }
    // eslint-disable-next-line
  }, [userID, getSpellingBits, spellingBitsStatus]);

  useEffect(() => {
    if (!isNil(userID) && isNil(wodGameStatus.data)) {
      dispatch(getWodGameStatus());
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  useEffect(() => {
    if (currentQn && isFirstLoad) {
      setProgressQn(currentQn.wordOrder);
      setCorrectCount(currentQn.wordOrder - 1);
    }
    // eslint-disable-next-line
  }, [currentQn]);

  // auto pronounce handling
  useEffect(() => {
    if (!showLoading) {
      setTimeout(() => {
        play();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoading]);

  // sound handling on hint activation
  useEffect(() => {
    const handleAudioEnd = () => {
      setTimeout(() => {
        setShowCountDown(true);
      }, 1000);
    };

    if (hint) {
      sound.on('end', handleAudioEnd);
    }

    return () => {
      if (sound) {
        sound.off('end', handleAudioEnd);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hint === true]);

  // to show start btn on loading page
  useEffect(() => {
    if (progress === 100) {
      const start = setInterval(() => {
        setShowStartBtn(true);
      }, 1000);
      return () => clearInterval(start);
    }
  }, [progress]);

  return (
    <>
      <Loading
        message={
          <LoadingContent>
            <p className="title">Get ready to spell!</p>
            {!isEmpty(currentList) && (
              <div className="word-list">
                {currentList.map((word) => (
                  <p key={word.wordOrder}>{word.wordText}</p>
                ))}
              </div>
            )}
          </LoadingContent>
        }
        progress={progress}
        showStartBtn={showStartBtn}
        startHandler={handleStart}
        fadeOutAnimation={isTransitioning}
      />
      <Container status={status} loading={showLoading}>
        {claimKoko && (
          <ClaimKokoCredit
            showModal={claimKoko}
            setShowModal={setClaimKoko}
            game="Spelling Bits"
            resultUrl={`/words-of-the-day/${subject}/spelling-bits/result`}
          />
        )}
        <SpellTopBar progress={correctCount} count={progressQn} />
        <Content>
          <div className="action-btns">
            <Voice disabled={hint} audioLink={currentQn?.voiceOverUrl} />
            <Hint
              onClick={hintHandler}
              disabled={hint || status === 'correct'}
              withHintSound={true}
            />
          </div>
          <img src={MikoHear} alt="miko" className="deco-miko" />
          {hint ? (
            <HintAnswer>
              <span className={showCountDown && 'fade-out'}>{answer}</span>
            </HintAnswer>
          ) : (
            <Input
              onChange={(e) => setAnswer(e.target.value)}
              status={status}
              disabled={STATUS_UI[status].inputDisabled || hint}
              value={answer}
              autoComplete="off"
              autoCapitalize="off"
            />
          )}
          {status === ANSWER_TYPE.WRONG && (
            <span className="wrong-icon">
              <img src={WrongIcon} alt="X" />
            </span>
          )}
        </Content>
        {!hint && (
          <Button
            disabled={isEmpty(answer) || submitResult?.isLoading}
            onClick={clickHandler}
          >
            {STATUS_UI[status].btnText}{' '}
            {submitResult.isLoading && <Spinner animation="border" size="sm" />}
          </Button>
        )}
        {showCountDown && (
          <HintCountDown duration={3} clearHandler={removeCountDown} />
        )}
        {status === ANSWER_TYPE.CORRECT && (
          <>
            <MeaningTranslateContainer>
              <MeaningAndTranslation
                handleOpen={handleOpen}
                displayContent={displayModalType}
              />
            </MeaningTranslateContainer>
            <MeaningAndTranslateModal
              openModal={showMeaningModal}
              type={displayModalType}
              closeHandler={handleClose}
              gameWordId={currentQn?.gameWordId}
              wordEntryId={currentQn?.wordEntryId}
              imgUrl={currentQn?.imageUrl}
              word={currentQn?.wordText}
              wordClass={currentQn?.wordClass}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default SpellingBits;
