import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { WOD_STATUS } from 'pages/WordsOfTheDay/constant';

import { Button } from 'components/Shared';

import { KokoIcon } from '../../imgs';

import { Container, Overlay } from './GameCard.styles';

const GameCard = ({ data, clickHandler }) => {
  const { t } = useTranslation(['common', 'wod']);
  const { subject } = useParams();
  const history = useHistory();

  const { wodGameStatus, wodPreferences } = useSelector((state) => state.wod);

  const allLoading = wodGameStatus.isLoading || wodPreferences.isLoading;

  const getBtnConfig = (status) => {
    switch (status) {
      case WOD_STATUS.LOCKED:
        return { text: t('common:startNow', 'Start Now'), variant: 'disable' };
      case WOD_STATUS.IN_PROGRESS:
        return { text: t('common:continue', 'Continue'), variant: 'orange4' };
      case WOD_STATUS.COMPLETED:
        return {
          text: t('common:tryAgain', 'Try Again'),
          variant: 'secondary3',
        };
      default:
        return { text: t('common:startNow', 'Start Now'), variant: 'orange4' };
    }
  };

  return (
    <Container icon={data.icon} top={data.topSpace}>
      <Overlay isLocked={data.status === WOD_STATUS.LOCKED} />
      <div className="no">{data.id}</div>
      <div className="game-info">
        <p className="game-name">
          {t(`wod:games.${data.translationKey}.name`, data.name)}
        </p>
        <p className="game-desc">
          {t(`wod:games.${data.translationKey}.desc`, data.desc)}
        </p>
      </div>
      <div className="rewards">
        <div className="koko">
          <img src={KokoIcon} alt="koko-icon" />
          <p>{t('wod:card.koko', 'Earn up to 20 KoKo')}</p>
        </div>
        {data.status === WOD_STATUS.COMPLETED && (
          <div
            className="result"
            onClick={() =>
              history.push(`/words-of-the-day/${subject}/${data.url}/result`)
            }
          >
            {t('common:seeResult', 'See Result')}
          </div>
        )}
      </div>
      <Button
        variant={getBtnConfig(data.status).variant}
        width="100%"
        onClick={clickHandler}
        isDisabled={allLoading}
      >
        {allLoading ? 'Loading...' : getBtnConfig(data.status).text}
      </Button>
    </Container>
  );
};

export default GameCard;
