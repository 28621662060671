import React from 'react';
import useSound from 'use-sound';

import { ReactComponent as HintIcon } from './hintIcon.svg';

import hintSound from './hint.mp3';

import { StyledContainer } from './Hint.styles';

const Hint = ({
  children,
  onClick,
  disabled,
  withHintSound = false,
  className,
}) => {
  const [play] = useSound(hintSound, {
    interrupt: false,
  });

  return (
    <StyledContainer disabled={disabled} className={className}>
      <HintIcon
        onClick={() => {
          if (withHintSound) {
            play();
          }
          onClick();
        }}
      />
      {children}
    </StyledContainer>
  );
};

export default Hint;
