import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem 2rem;
  .title {
    color: #ff6100;
    font-size: 22px;
    font-weight: 600;
  }
  .ok-btn {
    width: 96px;
  }
`;

export const Icon = styled.div`
  background: #ff8438;
  color: #ffffff;
  width: 62px;
  height: 62px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  top: -2rem;
`;
