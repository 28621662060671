import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BackButton } from 'components/Shared';

import { Container, RibbonContainer } from './ResultTopBar.styles';

const ResultTopBar = ({ game, backHandler }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: 630px)`,
  });

  return (
    <Container>
      <BackButton
        btnBgColor="#7b7b7b"
        backText="Back"
        className="back-btn"
        onClick={backHandler}
      />
      <RibbonContainer>
        Well done! {!isMobile && `You have completed ${game}.`}
      </RibbonContainer>
    </Container>
  );
};

export default ResultTopBar;
