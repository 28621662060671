import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'ramda';
import useSound from 'use-sound';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { pickWord } from 'store/dailyChallenge/randomWordsSlice';
import { updateWodTutorial } from 'store/wod/wodSlice';

import { SpellTopBar, HintCountDown } from './components';
import { Voice, Hint, Tutorial } from '../components';

import { STATUS_UI } from './constant';
import { WOD_GAMES } from '../GameList/constants';
import { ANSWER_TYPE } from '../constant';

import { MikoHear, WrongIcon } from './imgs';

import {
  Container,
  Content,
  Input,
  HintAnswer,
  Button,
} from './SpellingBits.styles';

const SpellingBitsOnboarding = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const { subject } = useParams();

  const audioLink =
    'https://s3.ap-southeast-1.amazonaws.com/dev-vocab.koobits.com/VoiceOver/0900cc82-de25-4d11-b670-2eedde0522cc.mp3';

  const [play, { isPlaying, sound, stop }] = useSound(audioLink, {
    interrupt: false,
  });

  const [answer, setAnswer] = useState('');
  const [status, setStatus] = useState(ANSWER_TYPE.DEFAULT);
  const [hint, setHint] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [progressQn, setProgressQn] = useState(1);

  const clickHandler = () => {
    if (status === ANSWER_TYPE.DEFAULT) {
      setStatus(ANSWER_TYPE.CORRECT);
      setProgressQn(progressQn + 1);
      dispatch(pickWord());
    }
    if (status === ANSWER_TYPE.WRONG) {
      setStatus(ANSWER_TYPE.DEFAULT);
      setAnswer('');
    }
  };

  const hintHandler = () => {
    if (isPlaying) {
      stop();
    }
    setHint(true);
    setAnswer('challenge');
    setStatus(ANSWER_TYPE.DEFAULT);
    setTimeout(() => {
      play();
    }, 1000);
  };

  const removeCountDown = () => {
    setTimeout(() => {
      setShowCountDown(false);
      setAnswer('');
      setHint(false);
    }, 1000);
  };

  useEffect(() => {
    const handleAudioEnd = () => {
      setTimeout(() => {
        setShowCountDown(true);
      }, 1000);
    };

    if (hint) {
      sound.on('end', handleAudioEnd);
    }

    return () => {
      if (sound) {
        sound.off('end', handleAudioEnd);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hint === true]);

  const steps = [
    {
      selector: '.spelling-bits-voice-icon',
      content: 'Listen to the audio and spell the word.',
      reposition: true,
    },
    {
      selector: '.spelling-bits-step-2',
      secondTarget: ['#spelling-bits-hint-answer', '.spelling-bits-count-down'],
      content: 'Need help? Click here to show the word.',
      reposition: true,
      position: 'right',
      hideArrow: true,
    },
    {
      selector: '#spelling-bits-input',
      secondTarget: '.spelling-bits-voice-icon',
      content: 'Spell and type the word inside the box.',
      reposition: true,
    },
    {
      position: 'center',
      content: '',
      link: `/words-of-the-day/${subject}/spelling-bits`,
    },
  ];

  const handleTutorialCallback = (index) => {
    if (index === 1) {
      setShowCountDown(true);
      setHint(true);
      setAnswer('challenge');
    }
    if (index === 2) {
      setShowCountDown(false);
      setHint(false);
      setAnswer('challenge');
    }
  };

  const updateTutorialStatusHandler = () => {
    dispatch(
      updateWodTutorial({
        gameType: WOD_GAMES.SPELLING_BITS.id,
        tutorialCompleted: true,
      })
    );
  };

  return (
    <>
      <Tutorial
        steps={steps}
        callback={handleTutorialCallback}
        closeHandler={updateTutorialStatusHandler}
      />
      <Container status={status} ref={containerRef}>
        <SpellTopBar progress={progressQn} />
        <Content>
          <div className="action-btns">
            <Voice
              disabled={hint}
              audioLink={audioLink}
              className="spelling-bits-voice-icon"
            />
            <Hint
              onClick={hintHandler}
              disabled={hint || status === 'correct'}
              withHintSound={true}
              className="spelling-bits-step-2"
            />
          </div>
          <img src={MikoHear} alt="miko" className="deco-miko" />
          {hint ? (
            <HintAnswer id="spelling-bits-hint-answer">
              <span className={showCountDown && 'fade-out'}>{answer}</span>
            </HintAnswer>
          ) : (
            <Input
              onChange={(e) => setAnswer(e.target.value)}
              status={status}
              disabled={STATUS_UI[status].inputDisabled || hint}
              value={answer}
              id="spelling-bits-input"
            />
          )}
          {status === 'wrong' && (
            <span className="wrong-icon">
              <img src={WrongIcon} alt="X" />
            </span>
          )}
        </Content>
        {!hint && (
          <Button disabled={isEmpty(answer)} onClick={clickHandler}>
            {STATUS_UI[status].btnText}
          </Button>
        )}
        {showCountDown && (
          <HintCountDown
            duration={3}
            clearHandler={removeCountDown}
            className="spelling-bits-count-down"
          />
        )}
      </Container>
    </>
  );
};

export default SpellingBitsOnboarding;
