import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isNil } from 'ramda';

import { WOD_GAME_TYPE } from '../constant';

import { getGameResult } from 'store/wod/wodSlice';

import { Button, Spinner } from 'components/Shared';
import { ResultTopBar } from '../components';

import {
  Container,
  ResultList,
  Header,
  ListContainer,
  WordItem,
  ActionBtns,
} from './SpellingBitsResult.styles';

const SpellingBitsResult = () => {
  const history = useHistory();
  const { subject } = useParams();
  const dispatch = useDispatch();

  const { userID, timezone } = useSelector((state) => state.login);
  const { data, isLoading } = useSelector((state) => state.wod.gameResult);

  useEffect(() => {
    if (!isNil(userID)) {
      dispatch(
        getGameResult({
          gameType: WOD_GAME_TYPE.SPELLING_BITS,
          inquiryDate: moment.tz(timezone).format('yyyy-MM-DD'),
        })
      );
    }
    // eslint-disable-next-line
  }, [userID, dispatch]);

  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const goToCoverPage = () => {
    history.push(`/words-of-the-day/${subject}`);
  };

  return (
    <Container>
      <ResultTopBar game="Spelling Bits" backHandler={goToCoverPage} />
      <ResultList>
        <Header id="headerwod">Words of the Day</Header>
        {isLoading && <Spinner />}
        {!isLoading && !isNil(data) && (
          <ListContainer>
            {data?.gameWords?.map((item, index) => (
              <WordItem key={item.gameWordId}>
                <span>{index + 1}.</span>
                <p>{item.wordText}</p>
              </WordItem>
            ))}
            <ActionBtns>
              <Button
                variant="secondary"
                width={isMobile ? '80%' : '188px'}
                className="result-btn"
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={goToCoverPage}
              >
                Try Again
              </Button>
              <Button
                variant="orange4"
                width={isMobile ? '80%' : '188px'}
                className="result-btn"
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={goToCoverPage}
              >
                Done
              </Button>
            </ActionBtns>
          </ListContainer>
        )}
      </ResultList>
    </Container>
  );
};

export default SpellingBitsResult;
