import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import useClickOutside from 'hooks/useClickOutside';

import { ReactComponent as ToggleIcon } from 'assets/img/icon-toggle.svg';
import {
  Selector,
  SelectButton,
  DropdownMenu,
  MenuItem,
} from './LanguageSelector.styles';

const LanguageSelector = ({ onLanguageChange, selectedLang }) => {
  const selectorRef = useRef();

  const { data } = useSelector((state) => state.wod.languagesList);

  const currentLang = data?.find((lang) => lang.languageCode === selectedLang);

  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(selectorRef, () => {
    setIsOpen(false);
  });

  const handleSelect = (langItem) => {
    onLanguageChange(langItem);
    setIsOpen(false);
  };

  return (
    <Selector ref={selectorRef}>
      <SelectButton onClick={() => setIsOpen(!isOpen)}>
        {currentLang?.name}
        <ToggleIcon />
      </SelectButton>
      <DropdownMenu isOpen={isOpen}>
        {data?.map((lang) => (
          <MenuItem key={lang.languageId} onClick={() => handleSelect(lang)}>
            {lang.name}
          </MenuItem>
        ))}
      </DropdownMenu>
    </Selector>
  );
};

export default LanguageSelector;
