import * as cn from 'chinese-numbering';
import { isNil } from 'ramda';

const languageKeys = {
  zh_CN: 'simplified',
  zh_HK: 'traditional',
  zh_TW: 'traditional',
};
const languageKeysArr = Object.keys(languageKeys);
// Formats numbers to chinese character numbers
const formatChineseNumber = ({ value, language }) => {
  const isCurrentLanguageChinese = languageKeysArr[language];
  if (!isCurrentLanguageChinese) return value;
  if (isNil(value)) return '';
  return cn.numberToChinese(value, languageKeys[language]);
};

export default formatChineseNumber;
