import useSound from 'use-sound';
import thatsRightSound from 'assets/audio/thatsright3.mp3';

const useCorrectSound = () => {
  const [play, { stop }] = useSound(thatsRightSound, { interrupt: true });

  return { play, stop };
};

export default useCorrectSound;
