import styled, { keyframes, css } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const StyledKeyboardContainer = styled.div`
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  background: #f0f0f0;
  padding: 10px;
  animation: ${({ showKeyboard }) =>
    showKeyboard &&
    css`
      ${slideUp} 0.3s ease-out
    `};
  z-index: 1000;
  .simple-keyboard {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }

  .simple-keyboard .hg-button {
    height: 40px;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  ${({ showKeyboard }) => {
    if (showKeyboard) {
      return css`
        position: relative;
        bottom: 0;
        animation: ${slideUp} 0.3s ease-out;
      `;
    }
  }}
`;
