import styled from 'styled-components';
import Logo from 'assets/img/logo-short.svg';

export const Container = styled.div`
  height: 62px;
  width: 100%;
  display: flex;
  background: #3c3737;
  color: #ffffff;
`;

export const LogoContainer = styled.div`
  width: 80px;
  height: 100%;
  background: url(${Logo}) no-repeat center center, #555555;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  .back-btn {
    padding: 0.25rem 1.2rem;
    position: absolute;
    left: 1rem;
    @media (max-width: 768px) {
      background: transparent;
      left: 0.2rem;
      .button-text {
        display: none;
      }
    }
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  p {
    font-family: 'Nunito';
    margin-bottom: 0;
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 700;
    span {
      color: #ffffff;
      font-size: 24px;
    }
  }
`;

export const ProgressBarLine = styled.div`
  background: #606060;
  border-radius: 11px;
  width: 280px;
  height: 21px;
  @media (max-width: 500px) {
    width: 178px;
  }
`;

export const ProgressBarContent = styled.div`
  background: #ff7f00;
  height: 21px;
  border-radius: 11px;
  width: ${({ width }) => `${width}%`};
  position: relative;
  transition: width 1s ease-out;
  .white-line {
    background: rgba(255, 196, 0, 0.41);
    border-radius: 5px;
    height: 5px;
    position: absolute;
    width: calc(100% - 25px);
    top: 4.5px;
    left: 10px;
  }
`;
