import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';

import { useHistory } from 'react-router-dom';

import accessFeature from 'helpers/accessFeature';
import { kooChallengeIcons } from 'constants/dashboardv2';

import {
  StyledContainer,
  StyledContent,
  PrimaryButton,
  ClickOverlay,
} from './WordOfTheDayCard.styles';

const HeroImage = ({ subject }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc = kooChallengeIcons[subject].wordOfTheDay;
  if (isTablet) {
    imgSrc = kooChallengeIcons[subject].wordOfTheDayTablet;
  }
  if (isMobile) {
    imgSrc = kooChallengeIcons[subject].wordOfTheDaySmall;
  }

  return <img src={imgSrc} alt="" />;
};

const WordOfTheDayCard = () => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);

  const { subject, currentProduct } = useSelector((state) => state.plan);

  // Event Handlers
  const accessFeatureHandler = () => {
    const { url, error } = accessFeature({
      type: 'wod',
      subject,
      featuresConfig: currentProduct?.features,
    });
    if (!error) {
      history.push(url);
    }
  };

  return (
    <StyledContainer subject={subject}>
      <HeroImage subject={subject} />
      <ClickOverlay data-cy="card-link" onClick={accessFeatureHandler} />
      <StyledContent>
        <PrimaryButton>{t(`dashboardCards:hero.Start`, 'Start')}</PrimaryButton>
      </StyledContent>
    </StyledContainer>
  );
};

export default WordOfTheDayCard;
