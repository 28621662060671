import { useState, useEffect } from 'react';
const useVirtualKeyboardDetection = () => {
  const [hasVirtualKeyboard, setHasVirtualKeyboard] = useState(false);

  useEffect(() => {
    const detectKeyboard = () => {
      const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

      const hasPhysicalKeyboard = 'external' in navigator && navigator.external;
      setHasVirtualKeyboard(hasTouch && !hasPhysicalKeyboard);
    };

    detectKeyboard();
  }, []);

  return { hasVirtualKeyboard };
};
export default useVirtualKeyboardDetection;
