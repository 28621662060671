import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import accessFeature from 'helpers/accessFeature';
import { kooChallengeIcons } from 'constants/dashboardv2';
import { rwd } from 'Theme';

import { StyledContainer } from './DailyChallengeLangCard.styles';

const CardImage = ({ subject }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc = kooChallengeIcons[subject].dc;
  if (isTablet) {
    imgSrc = kooChallengeIcons[subject].dcTablet;
  }
  if (isMobile) {
    imgSrc = kooChallengeIcons[subject].dcMobile;
  }

  return <img src={imgSrc} alt="" className="bg" />;
};

const DailyChallengeLangCard = () => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const { subject, currentProduct } = useSelector((state) => state.plan);
  // Event Handlers
  const clickHandler = () => {
    const { url, error } = accessFeature({
      type: 'dailychallenge',
      subject,
      featuresConfig: currentProduct?.features,
    });
    if (!error) {
      history.push(url);
    }
  };

  return (
    <StyledContainer onClick={clickHandler} subject={subject}>
      <div>
        <CardImage subject={subject} />
        <p>{t('dashboardCards:dailyChallenge.name', 'Daily Challenge')}</p>
      </div>
    </StyledContainer>
  );
};

export default DailyChallengeLangCard;
