export const handleReducer = ({
  initialState,
  fetchAction,
  stateTitle,
  customPending,
  customFulfilled,
  customRejected,
  builder,
}) => {
  builder
    .addCase(
      fetchAction.pending,
      customPending
        ? customPending
        : (state) => ({
            ...state,
            [stateTitle]: {
              ...initialState[stateTitle],
              isLoading: true,
              isError: null,
            },
          })
    )
    .addCase(
      fetchAction.rejected,
      customRejected
        ? customRejected
        : (state, action) => {
            state[stateTitle].isLoading = false;
            state[stateTitle].isError = action.error.message;
            state[stateTitle].data = null;
          }
    )
    .addCase(
      fetchAction.fulfilled,
      customFulfilled
        ? customFulfilled
        : (state, action) => {
            state[stateTitle].isLoading = false;
            state[stateTitle].data = action.payload;
          }
    );
};
