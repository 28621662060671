import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Dashboard from 'pages/Dashboard';
import Friends from 'pages/Friends';
import {
  Assignment,
  AssignmentView,
  PrintAssignment,
  AssignmentFinish,
  TestSubmitted,
  AssignmentSubmitted,
} from 'pages/Assignment';
import Login from 'pages/Login';
import AvatarCreator from 'pages/AvatarCreator';
import MonsterShowcase from 'pages/MonsterShowcase';
import Events from 'pages/Events';
import { Mission, SelfPracticeQnView, MysteryKoKo } from 'pages/Mission';
import {
  Multiplayer,
  PeerChallengeQnView,
  PeerChallengeResult,
} from 'pages/Multiplayer';
import AuthenticatedRoute from 'components/Shared/AuthenticatedRoute';
import {
  KooQuiz,
  KooQuizQnView,
  KooQuizSolution,
  KooQuizSolutionPreview,
  KooQuizFinish,
  KooQuizSubmitted,
} from 'pages/KooQuiz';
import {
  DailyChallengeRoute,
  DailyChallengeQnView,
} from 'pages/DailyChallenge';
import { featureToggle, MAINTENANCE_ROUTE } from 'constants/index';
import {
  SuperHero,
  SuperVision,
  SuperSpeed,
  SuperSpeedGame,
  SuperHeroHistory,
} from 'pages/SuperHero';
import Leaderboard from 'pages/Leaderboard';
import Report from 'pages/Report';
import { BrainGame } from 'pages/BrainGame';
import ExpiredSubs from 'pages/Expired';
import LoginRedirect from 'pages/LoginRedirect';
import ProfileSettings from 'pages/ProfileSettings';
import ParentPortal from 'pages/ParentPortal';
import PageViewTracking from 'components/PageViewTracking';
import KoKoHistory from 'pages/KoKoHistory';
import CPHistory from 'pages/CPHistory';

import {
  KooClassSolution,
  KooClassSubmitted,
  KooClassFinish,
  KooClassQnView,
  KooClass,
} from 'pages/KooClass';
import Story from 'pages/Story';
import FactualFluency from 'pages/FactualFluency';
import Notification from 'pages/Notification/index';
import ExpLevel from 'pages/ExpLevel/ExpLevel';

import {
  OTPTrial,
  OTPTrialExpired,
  BundleOTPTrial,
  TrialBlock,
} from 'pages/OTPTrial';

import { SplitCard } from 'pages/RnD';
import WordsOfTheDay from 'pages/WordsOfTheDay';

import { isNil } from 'ramda';
import useGlobalFeature from 'hooks/useGlobalFeature';
import useDashboard from 'hooks/useDashboard';

import CheckHistoryPopRouter from 'components/Shared/CheckHistoryPopRouter';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_SUBDOMAIN,
});

const Routes = () => {
  const { featuresHashmap } = useGlobalFeature(null, [
    'bookclub',
    'smc',
    'kooquiz',
    'assignment',
    'friends',
    'notifications',
  ]);
  const { isDashboardv2 } = useDashboard();

  return (
    <Router history={history}>
      <PageViewTracking>
        <Switch>
          <Route path="/redirect" component={LoginRedirect} />
          <AuthenticatedRoute
            exact
            path={MAINTENANCE_ROUTE}
            component={() => {
              window.open(
                'https://support.koobits.com/hc/en-gb/articles/4430653191449'
              );
              return <Redirect to="/dashboard" />;
            }}
          />

          {!featuresHashmap.smc.isEnabled && (
            <Redirect exact from="/events/open" to={MAINTENANCE_ROUTE} />
          )}
          {!featuresHashmap.kooquiz.isEnabled && (
            <Redirect
              exact
              path={[
                '/kooQuiz/solution/:subject/:kooQuizId/:kooQuizSubmissionID',
                '/kooQuiz/open',
              ]}
              to={MAINTENANCE_ROUTE}
            />
          )}
          {!featuresHashmap.assignment.isEnabled && (
            <Redirect exact path="/assignment/open" to={MAINTENANCE_ROUTE} />
          )}
          {!featuresHashmap.friends.isEnabled && (
            <Redirect path="/friends" to={MAINTENANCE_ROUTE} />
          )}
          {/* to disable notification if access from KooSchool */}
          {!featuresHashmap.notifications.isEnabled && !window.isKooSchool && (
            <Redirect path="/notification" to={MAINTENANCE_ROUTE} />
          )}
          <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />
          <AuthenticatedRoute
            exact
            isExpiredRoute
            path="/expired-subscription"
            component={ExpiredSubs}
          />
          <AuthenticatedRoute
            exact
            path="/dashboard/KoKoMonster"
            component={AvatarCreator}
          />
          <AuthenticatedRoute
            exact
            path="/MonsterShowcase"
            component={MonsterShowcase}
          />
          <AuthenticatedRoute exact path="/" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/members-login"
            render={({ location }) => {
              const query = new URLSearchParams(location.search);
              const redirectFlag = query.get('redirect');
              const entries = query.entries();
              const allSearchParams = Object.fromEntries(entries);
              let redirectString = allSearchParams.redirect;
              Object.entries(allSearchParams).forEach(([key, value]) => {
                if (key !== 'redirect') {
                  redirectString += `&${key}=${value}`;
                }
              });
              let redirectUrl = process.env.REACT_APP_MEMBERS_LOGIN_URL;
              if (!isNil(redirectFlag)) {
                redirectUrl += `?redirect=${redirectString}`;
              }
              window.location.href = redirectUrl;
            }}
          />

          {featureToggle.sundayMiniChallenge && (
            <AuthenticatedRoute
              exact
              path="/events/open"
              component={AssignmentView}
            />
          )}
          {featureToggle.sundayMiniChallenge && (
            <CheckHistoryPopRouter
              exact
              path="/events/submitted/result-release"
              redirectPathname={
                isDashboardv2 ? '/dashboard?view=koochallenge' : '/events'
              }
              component={TestSubmitted}
            />
          )}
          {featureToggle.sundayMiniChallenge && (
            <AuthenticatedRoute path="/events" component={Events} />
          )}
          {featureToggle.friends && (
            <AuthenticatedRoute path="/friends" component={Friends} />
          )}
          <AuthenticatedRoute
            exact
            path="/:type/print/:id"
            component={PrintAssignment}
          />
          <CheckHistoryPopRouter
            exact
            path="/assignment/open"
            redirectPathname="/assignment/assignment-list"
            component={AssignmentView}
          />
          <AuthenticatedRoute
            exact
            path="/assignment/finish"
            component={({ history }) =>
              history.action === 'POP' ? (
                <Redirect to={{ pathname: '/assignment/assignment-list' }} />
              ) : (
                <AssignmentFinish />
              )
            }
          />
          <AuthenticatedRoute
            exact
            path="/assignment/submitted/result-release"
            component={({ history }) =>
              history.action === 'POP' ? (
                <Redirect to={{ pathname: '/assignment/assignment-list' }} />
              ) : (
                <TestSubmitted />
              )
            }
          />
          <AuthenticatedRoute
            exact
            path="/assignment/submitted/claimkoko/:toClaim"
            component={AssignmentSubmitted}
          />
          <AuthenticatedRoute
            path="/KoKo/kokohistory"
            component={KoKoHistory}
          />
          <AuthenticatedRoute path="/cp/cp-history" component={CPHistory} />
          <AuthenticatedRoute path="/assignment" component={Assignment} />
          {featureToggle.mission && (
            <AuthenticatedRoute
              path={[
                '/mission/self-practice/:curriculumId/question-view/:id',
                '/mission/self-practice/plus/:id/question-view/:stageTypeId',
              ]}
              component={SelfPracticeQnView}
            />
          )}
          {featureToggle.mission && (
            <AuthenticatedRoute path="/mission" component={Mission} />
          )}
          {featureToggle.mission && (
            <AuthenticatedRoute
              exact
              path="/mystery-koko/:curriculumId/:id"
              component={MysteryKoKo}
            />
          )}
          {featureToggle.story && (
            <AuthenticatedRoute
              path={['/story', '/story-book']}
              component={Story}
            />
          )}

          {featureToggle.multiplayer && (
            <AuthenticatedRoute
              exact
              path="/multiplayer/peer-challenge/challenge-result/:id/:page"
              component={PeerChallengeResult}
            />
          )}
          {featureToggle.multiplayer && (
            <AuthenticatedRoute
              exact
              path="/multiplayer/peer-challenge/question-view"
              component={PeerChallengeQnView}
            />
          )}
          {featureToggle.multiplayer && (
            <AuthenticatedRoute path="/multiplayer" component={Multiplayer} />
          )}
          {featureToggle.dailyChallenge && (
            <AuthenticatedRoute
              path="/superhero/supervision"
              component={SuperVision}
            />
          )}
          {featureToggle.dailyChallenge && (
            <CheckHistoryPopRouter
              exact
              path="/challenges/daily-challenge/question-view"
              redirectPathname={
                isDashboardv2 ? '/dashboard?view=koochallenge' : '/challenges'
              }
              component={DailyChallengeQnView}
            />
          )}
          {featureToggle.dailyChallenge && (
            <AuthenticatedRoute
              exact
              path="/challenges/daily-challenge"
              component={DailyChallengeRoute}
            />
          )}
          {featureToggle.dailyChallenge && (
            <AuthenticatedRoute
              path="/challenges"
              component={DailyChallengeRoute}
            />
          )}
          {featureToggle.kooQuiz && (
            <AuthenticatedRoute
              exact
              path="/kooQuiz/solution-preview/:subject/:paper/:kooQuizId/:kooQuizSubmissionID"
              component={KooQuizSolutionPreview}
            />
          )}
          {featureToggle.kooQuiz && (
            <AuthenticatedRoute
              exact
              path="/kooQuiz/solution/:subject/:paper/:kooQuizId/:kooQuizSubmissionID"
              component={KooQuizSolution}
            />
          )}
          {featureToggle.kooQuiz && (
            <AuthenticatedRoute
              exact
              path="/kooQuiz/finish"
              component={KooQuizFinish}
            />
          )}
          {featureToggle.kooQuiz && (
            <AuthenticatedRoute
              exact
              path="/kooQuiz/open"
              component={KooQuizQnView}
            />
          )}
          {featureToggle.kooQuiz && (
            <AuthenticatedRoute
              exact
              path="/kooQuiz/submitted"
              component={KooQuizSubmitted}
            />
          )}
          {featureToggle.kooQuiz && (
            <AuthenticatedRoute path="/kooQuiz" component={KooQuiz} />
          )}
          <AuthenticatedRoute exact path="/superhero" component={SuperHero} />
          <AuthenticatedRoute
            exact
            path="/superhero/supervision-game"
            component={SuperVision}
          />
          <AuthenticatedRoute
            exact
            path="/superhero/superspeed"
            component={SuperSpeed}
          />
          <AuthenticatedRoute
            exact
            path="/superhero/superspeed/practice"
            component={() => <SuperSpeedGame practice />}
          />
          <AuthenticatedRoute
            exact
            path="/superhero/superspeed/challenge"
            component={() => <SuperSpeedGame practice={false} />}
          />
          <AuthenticatedRoute
            exact
            path="/superhero/history"
            component={SuperHeroHistory}
          />
          <AuthenticatedRoute path="/leaderboard" component={Leaderboard} />
          {featureToggle.report && (
            <AuthenticatedRoute path="/report" component={Report} />
          )}
          {featureToggle.brainGame && (
            <AuthenticatedRoute path="/brain-game" component={BrainGame} />
          )}
          {featureToggle.profile && (
            <AuthenticatedRoute
              path="/profile-settings"
              component={ProfileSettings}
            />
          )}
          {featureToggle.kooClass && (
            <CheckHistoryPopRouter
              exact
              path="/kooClass/open/:activityId/:topicId/:lessonId/:activityType"
              redirectPathname={`/kooClass/${
                history?.location.pathname.split('/')[4]
              }/lesson/${history?.location.pathname.split('/')[5]}/activity`}
              component={KooClassQnView}
            />
          )}
          {featureToggle.kooClass && (
            <AuthenticatedRoute
              exact
              path="/kooClass/finish/:activityId/:topicId/:lessonId/:activityType"
              component={KooClassFinish}
            />
          )}
          {featureToggle.kooClass && (
            <AuthenticatedRoute
              exact
              path="/kooClass/:topicId/submitted/:lessonId/:activityId"
              component={KooClassSubmitted}
            />
          )}
          {featureToggle.kooClass && (
            <AuthenticatedRoute
              exact
              path="/kooClass/:topicId/solution/:lessonId/:activityId"
              component={KooClassSolution}
            />
          )}
          {featureToggle.kooClass && (
            <AuthenticatedRoute path="/kooClass" component={KooClass} />
          )}
          <AuthenticatedRoute
            path="/factual-fluency"
            component={FactualFluency}
          />
          <AuthenticatedRoute
            isParentRoute
            path="/parent"
            component={ParentPortal}
          />
          {featureToggle.notification && !window.isKooSchool && (
            <AuthenticatedRoute path="/notification" component={Notification} />
          )}
          <AuthenticatedRoute exact path="/exp-level" component={ExpLevel} />
          <AuthenticatedRoute
            exact
            path="/:type/bundle"
            isExpiredRoute
            component={BundleOTPTrial}
          />
          <AuthenticatedRoute
            exact
            path="/trial/block"
            // isExpiredRoute
            component={TrialBlock}
          />
          <AuthenticatedRoute
            exact
            path="/trial/:subjectName"
            isExpiredRoute
            component={OTPTrial}
          />

          <AuthenticatedRoute
            exact
            path="/expired/:subjectName"
            isExpiredRoute
            component={OTPTrialExpired}
          />

          <AuthenticatedRoute
            exact
            path="/test/split-card"
            isExpiredRoute
            component={SplitCard}
          />
          <AuthenticatedRoute
            path="/words-of-the-day"
            component={WordsOfTheDay}
          />
          {/* we don't need these page anymore */}
          {/* branch name ET-2270 for create parent account */}
          <Redirect exact from="/create-parent-account" to="/members-login" />
          {/* we don't need these page anymore End */}
          <AuthenticatedRoute path="*" component={Dashboard} />
        </Switch>
      </PageViewTracking>
    </Router>
  );
};

export default Routes;
