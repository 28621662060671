import styled from 'styled-components';
export const StyledContainer = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  svg {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  @media screen and (max-width: ${({ theme }) => theme.rwd.tablet}px) {
    svg {
      width: 60px;
      height: 60px;
    }
  }
`;
