import React, { useState } from 'react';

import DisplayText from '../DisplayText';

import { Container } from './VocabCard.styles';

const VocabCard = ({
  url,
  textLength,
  showKeyboard = false,
  hintText,
  viewWidth,
  animatedOnHover,
  imgClassName,
  textClassName,
}) => {
  const [isHover, setIsHover] = useState(false);

  const getViewWidth = () => {
    switch (true) {
      case textLength > 35:
        return 1;
      case textLength > 25:
        return 1.1;
      case textLength > 15:
        return 1.2;
      case textLength > 8:
        return 1.3;
      default:
        return isHover ? 2.1 : 2;
    }
  };

  return (
    <Container
      className="imgContainer"
      animated={isHover}
      onMouseEnter={() => {
        if (animatedOnHover) {
          setIsHover(true);
        }
      }}
      onMouseLeave={() => {
        if (isHover) {
          setIsHover(false);
        }
      }}
    >
      <img src={url} alt="card" className={`${imgClassName} card-img`} />
      <DisplayText
        allLength={textLength}
        text={hintText}
        showKeyboard={showKeyboard}
        viewWidth={viewWidth && getViewWidth()}
        className={textClassName}
      />
    </Container>
  );
};

export default VocabCard;
