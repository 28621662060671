import styled from 'styled-components';

import { Grid, Deco, Name, Icon, MikoLevel } from './imgs';
import { isNil } from 'ramda';

export const Container = styled.div`
  margin-top: 59px;
  .modal-dialog {
    max-width: max-content;
  }
`;

export const Title = styled.div`
  height: 145px;
  background: url(${Name}) no-repeat calc(50% - 125px) center,
    url(${Icon}) no-repeat calc(50% + 205px) center,
    url(${Deco}) no-repeat center, url(${Grid}) repeat, #ffc14a;
  width: 100%;
  background-blend-mode: normal, normal, normal, overlay, normal;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .wod-back-btn {
    position: absolute;
    left: 2rem;
    top: 2rem;
    padding: 0.3rem 1.2rem;
  }

  @media (max-width: 1000px) {
    background: url(${Name}) no-repeat center center, url(${Grid}) repeat,
      #ffc14a;
    background-blend-mode: normal, overlay, normal;
    position: fixed;
  }
  @media (max-width: 700px) {
    justify-content: flex-end;
    .wod-back-btn {
      width: 35px;
      height: 35px;
      padding: 0;
      span {
        display: none;
      }
    }
  }
  @media (max-width: 585px) {
    background: url(${Name}) no-repeat calc(50% - 50px) center,
      url(${Grid}) repeat, #ffc14a;
    background-blend-mode: normal, overlay, normal;
    background-size: 50%, cover;
  }

  @media (max-width: 425px) {
    .wod-back-btn {
      left: 1rem;
    }
  }
`;

export const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 27rem;
  top: -0.3rem;
  cursor: ${({ currentLevel }) =>
    isNil(currentLevel) ? 'not-allowed' : 'pointer'};
  .level-name {
    background: #ffda40;
    font-weight: 600;
    font-size: 13px;
    width: max-content;
    padding: 5px 10px;
    border-radius: 15px;
    position: absolute;
    bottom: -0.7rem;
  }

  @media (max-width: 1000px) {
    left: 17rem;
  }

  @media (max-width: 700px) {
    left: 0;
    padding-right: 2rem;
    .level-name {
      position: inherit;
    }
  }
`;

export const LevelIcon = styled.div`
  height: 70.5px;
  width: 70.5px;
  background: url(${MikoLevel}) no-repeat center #ffffff;
  background-size: 65%;
  border-radius: 50%;
  border: 3px solid #ffda40;
  position: relative;
  @media (max-width: 700px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 5rem;
  padding-top: 3.5rem;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 12.5rem 2rem 2.5rem;
    gap: 2rem;
  }
`;
