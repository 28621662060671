import axios from 'axios';
import store from 'store';
const WOD_BASE_URL = process.env.REACT_APP_WOD_SERVICE_URL;

export const fetchWodPreferences = () => {
  return axios
    .get(`${WOD_BASE_URL}UserPreferences`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const postWodLevel = (body) => {
  return axios
    .post(`${WOD_BASE_URL}UserPreferences/level`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchWodGameStatus = () => {
  return axios
    .get(`${WOD_BASE_URL}UserPreferences/GameStatus`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const postWodTutorial = (body) => {
  return axios
    .post(`${WOD_BASE_URL}UserPreferences/tutorial`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchWordsList = (gameType) => {
  return axios
    .get(`${WOD_BASE_URL}KooCards/user/game/${gameType}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const postGameHint = (gameWordId) => {
  return axios
    .post(`${WOD_BASE_URL}Game/Hint/${gameWordId}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const postGameSubmit = (body) => {
  return axios
    .post(`${WOD_BASE_URL}Game/Submit`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchGameResult = (params) => {
  const { userID } = store.getState().login;
  return axios
    .get(
      `${WOD_BASE_URL}Game/Result/user/${userID}/type/${params.gameType}/date/${params.inquiryDate}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchWordDefinition = (gameWordId) => {
  return axios
    .get(`${WOD_BASE_URL}Game/${gameWordId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchRetryWordsList = (gameType) => {
  return axios
    .put(`${WOD_BASE_URL}KooCards/user/game/${gameType}/Retry`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchTranslationLanguages = () => {
  return axios
    .get(`${WOD_BASE_URL}Translation/Languages`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};

export const fetchWordTranslationByLangId = (params) => {
  return axios
    .get(
      `${WOD_BASE_URL}Translation/Word/${params.wordEntryId}/Lang/${params.languageId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err.response.data);
    });
};
