import React from 'react';

import useSound from 'use-sound';

import { StyledContainer } from './Voice.styles';

const Voice = ({ disabled, audioLink, className }) => {
  const [play, { isPlaying, stop }] = useSound(audioLink, {
    interrupt: false,
  });

  const handleClick = () => {
    if (isPlaying) {
      stop();
    }
    play();
  };

  const defaultWhite = isPlaying ? '#00b8ff' : '#fff';
  const defaultBlue = isPlaying ? '#fff' : '#00b8ff';

  return (
    <StyledContainer disabled={disabled} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="73"
        height="73"
        viewBox="0 0 73 73"
        onClick={handleClick}
      >
        <defs>
          <filter
            id="Ellipse_2496"
            x="0"
            y="0"
            width="73"
            height="73"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_8371"
              data-name="Rectangle 8371"
              width="33.902"
              height="23.684"
              fill="none"
            />
          </clipPath>
        </defs>
        <g
          id="Group_39207"
          data-name="Group 39207"
          transform="translate(-1213.008 -161)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 1213.01, 161)"
            filter="url(#Ellipse_2496)"
          >
            <circle
              id="Ellipse_2496-2"
              data-name="Ellipse 2496"
              cx="27.5"
              cy="27.5"
              r="27.5"
              transform="translate(9 6)"
              fill={defaultWhite}
            />
          </g>
          <g
            id="Group_35286"
            data-name="Group 35286"
            transform="translate(1233.104 183.558)"
          >
            <g id="Group_35285" data-name="Group 35285">
              <g id="Group_29606" data-name="Group 29606">
                <g id="Component_6_245" data-name="Component 6 – 245">
                  <g
                    id="Group_29597"
                    data-name="Group 29597"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Path_76411"
                      data-name="Path 76411"
                      d="M15.442.428a1.3,1.3,0,0,0-.988.45L7.147,5.926H1.4a1.3,1.3,0,0,0-.986.45A1.515,1.515,0,0,0,0,7.445v9.1a1.517,1.517,0,0,0,.417,1.066,1.3,1.3,0,0,0,.986.45H7.147l7.3,5.047a1.307,1.307,0,0,0,1.844.131,1.252,1.252,0,0,0,.131-.131,1.51,1.51,0,0,0,.416-1.066V1.945A1.516,1.516,0,0,0,16.426.879a1.3,1.3,0,0,0-.984-.451"
                      transform="translate(0 0.129)"
                      fill={defaultBlue}
                    />
                    <path
                      id="Path_76412"
                      data-name="Path 76412"
                      d="M15.761,6.349a1.316,1.316,0,0,0-.366,1.827,4.386,4.386,0,0,1-.065,5.1,1.342,1.342,0,0,0-.1.159,1.315,1.315,0,0,0,2.222,1.392,7.032,7.032,0,0,0,.538-.859,6.94,6.94,0,0,0-.45-7.3,1.312,1.312,0,0,0-1.78-.314"
                      transform="translate(4.516 1.837)"
                      fill={defaultBlue}
                    />
                    <path
                      id="Path_76413"
                      data-name="Path 76413"
                      d="M20.38,3.631a1.313,1.313,0,0,0-2.09,1.591,10.272,10.272,0,0,1,.338,11.9,1.3,1.3,0,0,0-.2.985,1.315,1.315,0,0,0,.574.846c.035.021.071.04.108.058a1.308,1.308,0,0,0,1.7-.434A12.883,12.883,0,0,0,20.38,3.631"
                      transform="translate(5.403 0.939)"
                      fill={defaultBlue}
                    />
                    <path
                      id="Path_76414"
                      data-name="Path 76414"
                      d="M23.59.543,23.533.471a1.313,1.313,0,0,0-2.021,1.678,16.211,16.211,0,0,1,.457,19.3,1.366,1.366,0,0,0-.084.139,1.313,1.313,0,0,0,2.235,1.37,19.282,19.282,0,0,0,1.237-2.018A18.885,18.885,0,0,0,23.59.543"
                      transform="translate(6.354 0)"
                      fill={defaultBlue}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </StyledContainer>
  );
};

export default Voice;
