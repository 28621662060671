import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Linotte', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background: #F9F9F9;
    overflow-x: hidden;
   
  }
  button {
    :focus {
      outline: none;
    }
  }
  .bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before{
    border-top-color: #113b51;
  }

  .bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #113b51;
}

  .bs-tooltip-auto[x-placement^=top], .bs-tooltip-top{
    bottom: 4px !important;
  }

  .tooltip-inner {
    background-color: #113b51;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .tooltip.show {
    opacity: 1;
  }
 /* // this for question table (from xml parser) */
 .table-container {
    overflow-x: auto;
  }
  .basic-table {
    border-collapse: collapse;
    border-bottom: 1px solid;
    width: 600px;
    table-layout: fixed;
    thead {
      td {
        font-weight: bold;
      }
    }
    td,
    th {
      text-align: center;
      border: 1px solid black;
      font-style: normal;
      vertical-align: middle;
      box-sizing: border-box;
      word-break: break-word;
      &:first-child {
        width: 45px;
      }
    }
    td {
      font-weight: normal;
    }
  }
  /* // this for question table (from xml parser) end */
  
  .reactour__helper {
    transition: unset!important;
}

`;

export default GlobalStyle;
