import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { defaultLang } from 'helpers/localizationNames';
import useClickOutside from 'helpers/useClickOutside';
import { updateLocalePreferences } from 'store/userPreferences/userPreferencesSlice';
import { ReactComponent as TickIcon } from 'assets/img/icon-black-tick.svg';
import { ReactComponent as Arrow } from 'assets/img/icon-arrow-btm.svg';
import { ReactComponent as LangIcon } from 'assets/img/icon-lang.svg';
import { Container, Toggle, Menu, Item } from './TranslationDropdown.styles';

const TranslationDropdown = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation(['navigation']);
  const { language } = useSelector((state) => state.login);
  const { isLoading } = useSelector(
    (state) => state.userPreferences.localPreferences
  );
  const [showOption, setShowOption] = useState(false);
  const activeLang = i18n.language;

  const displayLang = () => {
    switch (activeLang) {
      case 'zh_CN':
        return '中文-简';
      case 'zh_HK':
        return '中文';
      case 'zh_TW':
        return '中文';
      case 'ba':
        return 'Id';
      default:
        return 'En';
    }
  };
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    dispatch(updateLocalePreferences(code));
  };

  const outsideClickHandler = useClickOutside(() => setShowOption(false));
  return (
    <>
      <Container ref={outsideClickHandler}>
        <Toggle
          className="custom-toggle"
          show={showOption}
          data-cy="locale-btn"
          onClick={() => setShowOption(!showOption)}
        >
          <LangIcon className="custom-toggle" style={{ marginRight: '5px' }} />
          {isLoading ? <Spinner animation="border" size="sm" /> : displayLang()}
          <Arrow className="custom-toggle" style={{ marginLeft: '5px' }} />
        </Toggle>
        {showOption && (
          <Menu id="option">
            {language &&
              language.map((item) => (
                <Item
                  className="item"
                  key={item.Id}
                  onClick={() => {
                    setShowOption(false);
                    changeLanguage(
                      defaultLang(
                        item.TwoLetterIsolanguageName,
                        item.LocaleName,
                        item.NativeName
                      ).code
                    );
                  }}
                  active={
                    defaultLang(
                      item.TwoLetterIsolanguageName,
                      item.LocaleName,
                      item.NativeName
                    ).code === activeLang
                  }
                >
                  <div className="options">
                    <div className="dot" data-cy="dot">
                      {defaultLang(
                        item.TwoLetterIsolanguageName,
                        item.LocaleName,
                        item.NativeName
                      ).code === activeLang && <TickIcon data-cy="tick-icon" />}
                    </div>
                    <p>
                      {
                        defaultLang(
                          item.TwoLetterIsolanguageName,
                          item.LocaleName,
                          item.NativeName
                        ).name
                      }
                    </p>
                  </div>
                </Item>
              ))}
          </Menu>
        )}
      </Container>
    </>
  );
};

export default TranslationDropdown;
