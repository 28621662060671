import React from 'react';

const TranslateIcon = ({ isActive, onClick }) => {
  const renderWhite = isActive ? '#00b8ff' : '#fff';
  const renderBlue = isActive ? '#fff' : '#00b8ff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="73"
      height="73"
      viewBox="0 0 73 73"
      cursor={isActive ? 'default' : 'pointer'}
      onClick={onClick}
    >
      <defs>
        <filter
          id="Ellipse_2498"
          x="0"
          y="0"
          width="73"
          height="73"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_39209"
        data-name="Group 39209"
        transform="translate(-1286.5 -278.322)"
        style={{ isolation: 'isolate' }}
      >
        <g
          transform="matrix(1, 0, 0, 1, 1286.5, 278.32)"
          filter="url(#Ellipse_2498)"
        >
          <circle
            id="Ellipse_2498-2"
            data-name="Ellipse 2498"
            cx="27.5"
            cy="27.5"
            r="27.5"
            transform="translate(9 6)"
            fill={renderWhite}
          />
        </g>
        <g
          id="Group_35833"
          data-name="Group 35833"
          transform="translate(-8879.776 -10313.341)"
        >
          <rect
            id="Rectangle_5238"
            data-name="Rectangle 5238"
            width="22.724"
            height="22.724"
            rx="4"
            transform="translate(10189 10612)"
            fill={renderBlue}
          />
          <text
            id="文"
            transform="translate(10194.863 10626.361)"
            fill={renderWhite}
            fontSize="11"
            fontFamily="PingFangTC-Semibold, PingFang TC"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              文
            </tspan>
          </text>
          <path
            id="Union_13"
            data-name="Union 13"
            d="M6.3,16.528h-3a3.3,3.3,0,0,1-3.3-3.3V3.3A3.3,3.3,0,0,1,3.3,0h9.927a3.3,3.3,0,0,1,3.3,3.3v9.927a3.3,3.3,0,0,1-3.3,3.3H10.776L5.35,19.967Z"
            transform="translate(10202.162 10621.788)"
            fill={renderWhite}
            stroke={renderBlue}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.4"
          />
          <text
            id="A"
            transform="translate(10206.674 10633.361)"
            fill={renderBlue}
            fontSize="10"
            fontFamily="Nunito-ExtraBold, Nunito"
            fontWeight="800"
          >
            <tspan x="0" y="0">
              A
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export default TranslateIcon;
