export const WOD_GAME_TYPE = {
  KOOCARDS: 1,
  SPELLING_BITS: 2,
};

export const ANSWER_TYPE = {
  DEFAULT: 2,
  WRONG: 0,
  CORRECT: 1,
};

export const WOD_STATUS = {
  NEW: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  RETRY: 4,
  LOCKED: 5,
};
