import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  background: #fff0b2;
  color: #c6b260;
  font-weight: 600;
  font-size: 20px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  text-align: center;
  padding: 0.8rem 0;
`;

export const Content = styled.div`
  padding: 1rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: 700px) {
    padding: 1rem 2rem 2rem;
  }
  @media (max-width: 515px) {
    padding: 1rem 1.5rem 2rem;
  }
`;

export const DescContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;
  span {
    font-weight: 600;
    font-size: 22px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const OptionCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ isActive }) => (isActive ? '#F88F04' : '#f4f4f4')};
  border-radius: 16px;
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.13);

  .level-name {
    padding: 1rem;
    display: flex;
    background: #fff0b2;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    gap: 8px;
    justify-content: center;
    align-items: flex-start;
    .name {
      font-weight: 600;
      font-size: 1rem;
      .name-desc {
        display: none;
      }
    }
  }
  .level-icon {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    background: #ffffff;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  @media (max-width: 1000px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .level-name {
      background: #ffffff;
      border-bottom-left-radius: 16px;
      align-items: center;
      gap: 1rem;
      .name {
        .name-desc {
          display: block;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .level-icon {
      border-top-right-radius: 16px;
      .miko {
        display: none;
      }
    }
  }
  @media (max-width: 1000px) {
    .level-name {
      padding-right: 0;
      .name {
        .name-desc {
          width: 170px;
        }
      }
    }
    .level-icon {
      padding: 2rem 1rem 2rem 0;
      img {
        width: 80%;
      }
    }
  }

  @media (max-width: 515px) {
    .level-name {
      .name {
        .name-desc {
          width: 150px;
        }
      }
    }
  }

  @media (max-width: 385px) {
    .level-name {
      .name {
        .name-desc {
          width: 100px;
        }
      }
    }
  }
`;

export const CheckedBullet = styled.span`
  width: 20px;
  height: 20px;
  background: ${({ isActive }) => (isActive ? '#f88f04' : '#E6E5E1')};
  border: 5px solid #ffffff;
  border-radius: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  .action-btn {
    width: 134px;
  }
`;
