import styled from 'styled-components';
import { multiplayerListStyles } from 'constants/multiplayer';
import { CHINESE_LANGUAGES } from 'constants/index';

export const MainContainer = styled.div``;

export const LeftContent = styled.div`
  display: flex;
  .info-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #0d0d0d;
    margin-left: 1rem;
    .challenge-name {
      font-size: 24px;
      font-weight: 600;
    }
    .challenge-energy {
      display: flex;
      align-items: center;
      .remaining {
        margin: 1.5rem 0 0 0.8rem;
        font-size: 20px;
        display: flex;
        align-items: baseline;
        p {
          margin: 0px 5px 0px 0px;
        }
        span {
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .info-section {
      .icon {
        flex-basis: 25%;
      }
      .challenge-energy {
        .challenge-icon {
          img {
            width: 25px;
          }
        }
        .remaining {
          flex: 1;
          font-size: 14px;
          span {
            font-size: 14px;
            text-align: justify;
          }
        }
      }
      .challenge-name {
        font-size: 20px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .info-section {
      .challenge-name {
        font-size: 18px;
      }
    }
  }
`;

export const SubCardContainer = styled.div`
  width: 200px;
  height: 200px;
  background: ${(props) =>
    multiplayerListStyles[props.subject].card.background};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .text {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 1rem;
    p {
      margin-bottom: 0px;
    }
    .text-closed {
      color: #916100;
      font-size: 17px;
      text-transform: uppercase;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 160px;
    height: 160px;
    .text {
      font-size: 18px;
    }
    img {
      width: 80%;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100px;
    height: 100px;
    .text {
      font-size: 14px;
    }
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 0px;
  }
  a {
    color: #ff7121;
  }
  .text {
    margin-bottom: 0.5rem;
    text-align: center;
    .closed,
    span {
      text-transform: uppercase;
      color: #ff7121;
      font-size: 18px;
    }
    .open-hour {
      font-size: 14px;
    }
  }
  .new-challenge {
    color: #ff7121;
    font-weight: 600;
    margin-top: 1rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .text {
      .view-text,
      .closed,
      span {
        font-size: 16px;
      }
      .open-hour {
        font-size: 12px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex: 1;
    .text {
      .view-text,
      .closed,
      span {
        font-size: 12px;
      }
      .open-hour {
        font-size: 10px;
      }
    }
    .new-challenge {
      color: #ff7121;
      font-weight: 600;
      font-size: 12px;
      margin-top: 0.5rem;
    }
  }
`;

export const HideAtMobile = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const ShowAtMobile = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: block;
    flex: 1;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    .challenge-energy {
      display: flex;
      flex-direction: ${(props) =>
        CHINESE_LANGUAGES[props.language] ? 'column' : 'row'};
      align-items: center;
      .remaining {
        flex: 1;
        margin-left: 5px;
        font-size: 14px;
        display: flex;
        p {
          margin: 0px 5px 0px 0px;
        }
        span {
          color: rgb(33, 33, 33, 0.54);
          font-size: 12px;
          text-align: justify;
        }
      }
      .challenge-icon {
        img {
          width: 25px;
        }
      }
    }
  }
`;
