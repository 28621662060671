import { useEffect, useState } from 'react';
export const useLoading = (loading) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;

    if (progress < 50) {
      timer = setInterval(() => {
        setProgress((prev) => Math.min(50, prev + 10));
      }, 250);
    } else if (!loading && progress < 100) {
      setProgress(100);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [progress, loading]);
  return { progress };
};
