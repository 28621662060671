import { isNil } from 'ramda';
import { getCurrentDateTimeInTimezone } from 'helpers/timezone';
import smcState from 'helpers/Dashboard/smcState';

export const ERROR_TYPES = {
  err001: {
    code: 'err001',
    message: 'Feature is currently closed',
  },
  err002: {
    code: 'err002',
    message: 'Feature is not available for your plan',
  },
  err003: {
    code: 'err003',
    message: 'Feature is for premium plan only',
  },
};

const FEATURE_TYPES = {
  dailychallenge: {
    name: 'dailychallenge',
    configKey: 'dc',
    hasUrlConstructor: false,
    url: {
      math: '/challenges/daily-challenge',
      science: '/challenges/science',
      english: '/challenges/daily-challenge',
    },
    validator: ({ timezone }) => {
      if (isNil(timezone)) throw new Error('Timezone undefined');
      const currentDateTime = getCurrentDateTimeInTimezone(timezone);
      const startTime = getCurrentDateTimeInTimezone(timezone).set({
        hour: 6,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const endTime = getCurrentDateTimeInTimezone(timezone).set({
        hour: 22,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const isOpen = currentDateTime.isBetween(startTime, endTime);
      if (!isOpen) throw new Error('err001');
    },
  },
  peerchallenge: {
    name: 'peerchallenge',
    configKey: 'pc',
    hasUrlConstructor: false,
    url: {
      math: '/multiplayer/peer-challenge/new-challenge?subject=Math',
      science: '/multiplayer/peer-challenge/new-challenge?subject=Science',
      english: '/multiplayer/peer-challenge/new-challenge?subject=English',
    },
    validator: ({ timezone }) => {
      if (isNil(timezone)) throw new Error('Timezone undefined');
      const currentDateTime = getCurrentDateTimeInTimezone(timezone);
      const startTime = getCurrentDateTimeInTimezone(timezone).set({
        hour: 6,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const endTime = getCurrentDateTimeInTimezone(timezone).set({
        hour: 22,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const isOpen = currentDateTime.isBetween(startTime, endTime);
      if (!isOpen) throw new Error('err001');
    },
  },
  kooclass: {
    name: 'kooclass',
    configKey: 'kooclass',
    hasUrlConstructor: true,
    urlConstructor: ({ level, courseId }) => {
      if (isNil(level) || isNil(courseId)) {
        throw new Error('Url params undefined');
      }
      return `/dashboard?view=kooclass&courseid=${courseId}`;
    },
  },
  kooquiz: {
    name: 'kooquiz',
    configKey: 'kooquiz',
    hasUrlConstructor: false,
    url: {
      math: '/kooQuiz/Math/1',
      science: '/kooQuiz/Science/4',
      english: '/kooQuiz/English/5',
    },
  },
  smc: {
    name: 'sundayminichallenge',
    configKey: 'smc',
    hasUrlConstructor: true,
    urlConstructor: ({ status, eventData }) => {
      const nonClickableStates = ['not-submitted', 'submitted'];
      if (nonClickableStates.includes(status))
        return '/dashboard?view=koochallenge&hero=2';
      if (status === 'open') {
        return `/events/start/${eventData.EventId}`;
      }
      if (['submitted-claimable', 'submitted-claimed'].includes(status)) {
        return `/events/view-result/${eventData.TestSubmissionId}`;
      }
      return '/dashboard?view=koochallenge&hero=2';
    },
  },
  braingames: {
    name: 'braingames',
    configKey: 'braingames',
    hasUrlConstructor: false,
    url: {
      math: '/brain-game/math',
      science: '/brain-game/science',
      english: '/brain-game/english',
    },
  },
  story: {
    name: 'story',
    configKey: 'story',
    hasUrlConstructor: false,
    url: {
      math: '/story/math',
      science: '/story/science',
      english: '/story/english',
    },
  },
  wod: {
    name: 'wod',
    configKey: 'wod',
    hasUrlConstructor: false,
    url: {
      math: '/',
      science: '/',
      english: '/words-of-the-day/english',
    },
    validator: ({ timezone }) => {
      if (isNil(timezone)) throw new Error('Timezone undefined');
      const currentDateTime = getCurrentDateTimeInTimezone(timezone);
      const startTime = getCurrentDateTimeInTimezone(timezone).set({
        hour: 6,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const endTime = getCurrentDateTimeInTimezone(timezone).set({
        hour: 22,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const isOpen = currentDateTime.isBetween(startTime, endTime);
      if (!isOpen) throw new Error('err001');
    },
  },
};

// Helper function that does validation for access to a type of feature and returns an object with the url to access feature
// Sample object returned:
// {
//     url: '/somewhere',
//     error: null
// }
const accessFeature = ({
  type,
  subject,
  eventData,
  featuresConfig,
  defaultLevel = 1,
  timezone,
  withValidation = false,
}) => {
  if (
    isNil(type) ||
    isNil(subject) ||
    isNil(FEATURE_TYPES[type]) ||
    isNil(featuresConfig)
  ) {
    return {
      error: 'Params undefined',
    };
  }
  // Get url
  const feature = FEATURE_TYPES[type];
  const lowercaseSubject = subject.toLowerCase();
  // Check if feature is enabled in config
  const config = featuresConfig.find(
    (configItem) => configItem.feature.toLowerCase() === feature.configKey
  );
  if (isNil(config) || config.parameters?.IsEnabled !== true) {
    return {
      error: feature.name === 'sundayminichallenge' ? 'err002' : 'err003',
    };
  }
  // Validation enabled
  if (withValidation && !isNil(feature.validator)) {
    try {
      feature.validator({ timezone });
    } catch (err) {
      return {
        error: err.message,
      };
    }
  }
  if (feature.hasUrlConstructor) {
    // SMC
    if (feature.name === 'sundayminichallenge') {
      try {
        const url = feature.urlConstructor({
          status: smcState(eventData, timezone),
          eventData,
        });
        return {
          url,
        };
      } catch (err) {
        return {
          error: err.message,
        };
      }
    }
    if (feature.name === 'kooclass') {
      try {
        const url = feature.urlConstructor({
          level: defaultLevel,
          courseId: config.parameters?.CourseId,
        });
        return {
          url,
        };
      } catch (err) {
        return {
          error: err.message,
        };
      }
    }
  } else {
    const url = feature.url[lowercaseSubject];
    return isNil(url)
      ? {
          error: 'err002',
        }
      : {
          url,
        };
  }
};

export default accessFeature;
