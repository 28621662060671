import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { updateWodTutorial } from 'store/wod/wodSlice';

import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';

import { ANSWER_TYPE } from '../constant';
import { WOD_GAMES } from '../GameList/constants';

import demoCard from './imgs/demo_card.png';

import { Button } from 'components/Shared';
import {
  Hint,
  Voice,
  Tutorial,
  VocabCard,
} from 'pages/WordsOfTheDay/components';
import { Modifier } from '../KooCards/components';

import {
  StyledContainer,
  StyledAnswer,
  StyledQuestion,
  StyledCardContainer,
  StyledActionContainer,
  StyledProgressContainer,
  StyledAnswerArea,
  StyledBackButton,
  StyledProgress,
} from './KooCards.styles';

const currentAnswerType = ANSWER_TYPE.DEFAULT;
const TEXT = 'device';

const Onboarding = () => {
  const { t } = useTranslation(['wod', 'common']);
  const containerRef = useRef(null);
  const isBelowTablet = useIsBelowTablet();
  const { subject } = useParams();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');
  const [hintText, setHintText] = useState('d');

  const inputRef = useRef(null);
  const percentage = (1 / (TEXT.length - 1)) * (hintText.length - 1);

  const steps = [
    {
      selector: '#answerInput',
      secondTarget: '.imgContainer',
      position: 'bottom',
      content: t(
        'wod:kooCards.tutorial.keyIn',
        'Look at the card. Key in the answer.'
      ),
      isAuto: false,
      skipTo: null,
      type: 'koocards',
    },
    {
      selector: '.hint',
      secondTarget: '.step2',
      reposition: true,
      content: t(
        'wod:kooCards.tutorial.help',
        'Need help? Click here to show more hints.'
      ),
      isAuto: true,
      skipTo: 5,
      type: 'koocards',
    },
    {
      selector: '.hint',
      secondTarget: '.step2',
      reposition: true,
      content: t(
        'wod:kooCards.tutorial.help',
        'Need help? Click here to show more hints.'
      ),
      isAuto: true,
      skipTo: 5,
      type: 'koocards',
    },
    {
      selector: '.hint',
      secondTarget: '.step2',
      reposition: true,
      content: t(
        'wod:kooCards.tutorial.help',
        'Need help? Click here to show more hints.'
      ),
      isAuto: true,
      skipTo: 5,
      type: 'koocards',
    },
    {
      selector: '.hint',
      secondTarget: '.step2',
      reposition: true,
      content: t(
        'wod:kooCards.tutorial.help',
        'Need help? Click here to show more hints.'
      ),
      isAuto: true,
      skipTo: 5,
      type: 'koocards',
    },
    {
      selector: '#answerInput',
      secondTarget: '.imgContainer',
      content: t(
        'wod:kooCards.tutorial.answer',
        'Key in the correct answer here.'
      ),
      isAuto: false,
      skipTo: null,
      type: 'koocards',
    },
    {
      position: 'center',
      content: '',
      link: `/words-of-the-day/${subject.toLowerCase()}/koocards`,
      isAuto: false,
      skipTo: null,
      type: 'koocards',
    },
  ];
  const handleTutorialCallback = (index) => {
    if (index > 1 && index < 5) {
      setHintText(TEXT.slice(0, index));
    }
    if (index === 5) {
      setInputValue(TEXT);
    }
  };

  const updateTutorialStatusHandler = () => {
    dispatch(
      updateWodTutorial({
        gameType: WOD_GAMES.KOOCARDS.id,
        tutorialCompleted: true,
      })
    );
  };

  return (
    <>
      <Tutorial
        steps={steps}
        callback={handleTutorialCallback}
        closeHandler={updateTutorialStatusHandler}
      />
      <StyledContainer ref={containerRef} noNeedLoading>
        <StyledQuestion>
          <StyledBackButton
            backText={isBelowTablet ? '' : t('common:back', 'Back')}
            btnBgColor="#404447"
            padding="0.3rem 1.2rem"
            onClick={() => console.log('hi')}
          />
          <StyledCardContainer>
            <VocabCard
              url={demoCard}
              textLength={TEXT.length}
              hintText={hintText}
              imgClassName="step1"
              textClassName="step2"
            />
            <Modifier />
          </StyledCardContainer>
          <StyledActionContainer>
            <Hint className="hint">
              <StyledProgress now={100 - Math.floor(percentage * 100)} />
            </Hint>
            <Voice
              audioLink={
                'https://s3.ap-southeast-1.amazonaws.com/dev-vocab.koobits.com/VoiceOver/game_over.mp3'
              }
            />
          </StyledActionContainer>
        </StyledQuestion>
        <StyledAnswer>
          <StyledProgressContainer>
            <div className="question-progress">
              <span>0</span>/10
            </div>
            <ProgressBar now={0} />
          </StyledProgressContainer>
          <StyledAnswerArea answerType={currentAnswerType}>
            <div className="input-wrap">
              <div className="input-container" id="answerInput">
                <input
                  value={inputValue}
                  placeholder={'d'}
                  disabled={currentAnswerType !== ANSWER_TYPE.DEFAULT}
                  ref={inputRef}
                  readOnly={true}
                  type="text"
                  autoComplete="off"
                  inputMode="none"
                />
              </div>

              <Button
                margin={isBelowTablet ? '1rem auto 0.75rem' : '2rem auto 0'}
                variant="primary"
                width={isBelowTablet ? '96%' : '100%'}
              >
                {t('common:check', 'Check')}
              </Button>
            </div>
          </StyledAnswerArea>
        </StyledAnswer>
      </StyledContainer>
    </>
  );
};

export default Onboarding;
