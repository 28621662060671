import React from 'react';

import MeaningIcon from './MeaningIcon';
import TranslateIcon from './TranslateIcon';

import { BUTTONS } from './constants';

import { StyledImgContainer, Container } from './MeaningAndTranslation.styles';

const MeaningAndTranslation = ({ handleOpen, displayContent }) => {
  return (
    <Container>
      <StyledImgContainer>
        <MeaningIcon
          isActive={displayContent === BUTTONS.MEANING}
          onClick={() => handleOpen(BUTTONS.MEANING)}
        />
      </StyledImgContainer>
      <StyledImgContainer>
        <TranslateIcon
          isActive={displayContent === BUTTONS.TRANSLATE}
          onClick={() => handleOpen(BUTTONS.TRANSLATE)}
        />
      </StyledImgContainer>
    </Container>
  );
};

export default MeaningAndTranslation;
