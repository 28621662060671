import { ProgressBar } from 'react-bootstrap';
import styled, { css, keyframes } from 'styled-components';

const fadeoutAnimate = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
    z-index:0;
  }
`;

export const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232123;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 12px 12px;
  z-index: 10;

  > div {
    max-width: 460px;
    text-align: center;
  }
  .loading-container {
    position: relative;
    .loading-light {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      width: 100%;
      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }

  .content {
    .start-btn {
      width: 80%;
      margin: auto;
    }
  }

  ${({ fadeout }) => {
    if (fadeout) {
      return css`
        animation: ${fadeoutAnimate} 1s ease-in-out forwards;
      `;
    }
  }}
`;
export const StyledProgress = styled(ProgressBar)`
  height: 20px;
  border-radius: 10px;
  max-width: 280px;
  margin: 0 auto;
  .progress-bar {
    position: relative;
    background-color: #ff6701;
    border-radius: 10px;
    ::after {
      position: absolute;
      top: 2px;
      right: 10px;
      display: block;
      content: '';
      width: 10px;
      height: 5px;
      background: rgba(255, 196, 0, 0.4);
      border-radius: 5px;
    }
  }
`;
