import React from 'react';

const MeaningIcon = ({ isActive, onClick }) => {
  const renderWhite = isActive ? '#00b8ff' : '#fff';
  const renderBlue = isActive ? '#fff' : '#00b8ff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="73"
      height="73"
      viewBox="0 0 73 73"
      cursor={isActive ? 'default' : 'pointer'}
      onClick={onClick}
    >
      <defs>
        <filter
          id="Ellipse_2497"
          x="0"
          y="0"
          width="73"
          height="73"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_39208"
        data-name="Group 39208"
        transform="translate(-1286.5 -204)"
        style={{ isolation: 'isolate' }}
      >
        <g
          transform="matrix(1, 0, 0, 1, 1286.5, 204)"
          filter="url(#Ellipse_2497)"
        >
          <circle
            id="Ellipse_2497-2"
            data-name="Ellipse 2497"
            cx="27.5"
            cy="27.5"
            r="27.5"
            transform="translate(9 6)"
            fill={renderWhite}
          />
        </g>
        <g
          id="Group_35425"
          data-name="Group 35425"
          transform="translate(1083.794 -355.394)"
        >
          <path
            id="Subtraction_18"
            data-name="Subtraction 18"
            d="M15.253,25.584H4.8a4.8,4.8,0,0,1-4.8-4.8V4.8A4.8,4.8,0,0,1,4.8,0h16.39a1.2,1.2,0,0,1,1.2,1.2V12.144a7.666,7.666,0,0,0-10.4,7.044H4.8a1.6,1.6,0,1,0,0,3.2h7.83a7.681,7.681,0,0,0,2.622,3.2ZM6.7,9.593a.3.3,0,0,0-.3.3v1a.3.3,0,0,0,.3.3H17.289a.3.3,0,0,0,.3-.3v-1a.3.3,0,0,0-.3-.3Zm0-3.2a.3.3,0,0,0-.3.3v1a.3.3,0,0,0,.3.3H17.289a.3.3,0,0,0,.3-.3v-1a.3.3,0,0,0-.3-.3Z"
            transform="translate(226.619 578.197)"
            fill={renderBlue}
          />
          <rect
            id="Rectangle_9691"
            data-name="Rectangle 9691"
            width="4.364"
            height="8.834"
            rx="2"
            transform="matrix(0.848, -0.53, 0.53, 0.848, 245.547, 600.1)"
            fill={renderBlue}
          />
          <g
            id="Ellipse_2452"
            data-name="Ellipse 2452"
            transform="translate(241.855 592.266)"
            fill={renderWhite}
            stroke={renderBlue}
            strokeWidth="2"
          >
            <circle cx="5.521" cy="5.521" r="5.521" stroke="none" />
            <circle cx="5.521" cy="5.521" r="4.521" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MeaningIcon;
