import {
  ZeroIcon,
  ZeroMiko,
  BeginnerIcon,
  BeginnerMiko,
  SchoolIcon,
  SchoolMiko,
  AdvancedIcon,
  AdvancedMiko,
  MikoKooCards,
  MikoSpellingBits,
} from './imgs';

export const WOD_LEVEL_OPTIONS = {
  ZERO: {
    id: 1,
    icon: ZeroIcon,
    miko: ZeroMiko,
    name: 'Start From Zero',
    desc: 'Start with the easiest words to grow your confidence!',
    translationKey: 'zero',
  },
  BEGINNER: {
    id: 2,
    icon: BeginnerIcon,
    miko: BeginnerMiko,
    name: 'Beginner Level',
    desc: 'Catch up on words you might have missed before!',
    translationKey: 'beginner',
  },
  SCHOOL: {
    id: 3,
    icon: SchoolIcon,
    miko: SchoolMiko,
    name: 'School Level',
    desc: 'Keep pace with words you are learning in school now!',
    translationKey: 'school',
  },
  ADVANCED: {
    id: 4,
    icon: AdvancedIcon,
    miko: AdvancedMiko,
    name: 'Advanced Level',
    desc: 'Challenge yourself by mastering words above your level!',
    translationKey: 'advanced',
  },
};

export const WOD_GAMES = {
  KOOCARDS: {
    id: 1,
    name: 'KooCards',
    desc: 'Visual Vocabulary',
    icon: MikoKooCards,
    topSpace: '15px',
    translationKey: 'koocards',
    url: 'koocards',
  },
  SPELLING_BITS: {
    id: 2,
    name: 'Spelling Bits',
    desc: 'Learn, Practise',
    icon: MikoSpellingBits,
    topSpace: '5px',
    translationKey: 'spelling',
    url: 'spelling-bits',
  },
};
