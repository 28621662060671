import styled from 'styled-components';
import { iconScale } from 'constants/dashboardv2';

export const StyledContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;

  :hover {
    > div {
      transform: ${({ subject }) => `scale(${iconScale[subject]})`};
    }
  }
  .bg {
    width: 100%;
    height: auto;
    filter: none;
  }
  .incoming-icon {
    position: absolute;
    top: 9.45%;
    right: 0.15rem;
    width: 20.15%;
    height: auto;
  }
  p {
    position: absolute;
    bottom: 17%;
    text-align: center;
    width: 100%;
    font-weight: 700;
    color: white;
    font-size: calc(0.01586157173 * 100vw);
    margin: 0;
    filter: none;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: auto;
    margin-right: 10px;
    .bg {
      width: 206px;
    }
    p {
      font-size: 22px;
      bottom: 12%;
      line-height: normal;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-right: 0px;
    .bg {
      width: 100%;
    }
    p {
      font-size: 20px;
      bottom: 22%;
    }
  }
`;
