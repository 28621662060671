import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Shared';

import { Container, Icon } from './RestrictionModal.styles';

const RestrictionModal = ({ clickHandler }) => {
  const { t } = useTranslation(['common', 'wod']);
  return (
    <Container>
      <Icon>!</Icon>
      <p className="title">Oops!</p>
      <p>
        {t(
          'wod:modalMsg',
          'It seems that you have already completed at least one question. You cannot change the difficulty level for today.'
        )}
      </p>
      <Button variant="orange4" className="ok-btn" onClick={clickHandler}>
        {t('common:ok', 'OK')}
      </Button>
    </Container>
  );
};

export default RestrictionModal;
